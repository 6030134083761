import { useQuery } from '@apollo/client';
import CONSULTATION_COUNT_WEEKLY from '../../../graphql/consultationsCountWeekly';
import ErrorPage from '../../error';
import LoadingPage from '../../loading';

function ConsultationsCountWeeklyContainer() {
  /**
   * Apollo
   */
  const {
    data: consultationsCountWeeklyData,
    loading: consultationsCountWeeklyLoading,
    error: consultationsCountWeeklyError,
  } = useQuery(CONSULTATION_COUNT_WEEKLY, { fetchPolicy: 'network-only' });

  if (consultationsCountWeeklyLoading) {
    return <LoadingPage />;
  }

  if (consultationsCountWeeklyError || !consultationsCountWeeklyData?.consultationsCountWeekly) {
    return <ErrorPage />;
  }

  return (
    <div>
      <p className="text-xl font-bold">Nombre de consultations pour la semaine en cours :</p>
      {consultationsCountWeeklyData.consultationsCountWeekly.map((x) => (
        <div key={x.practitionerUuid}>
          <p>
            Consultations de{' '}
            <span className="font-bold">
              {x.lastname} {x.firstname}
            </span>{' '}
            pour la semaine en cours : <span className="font-bold">{x.count}</span>
          </p>
        </div>
      ))}
    </div>
  );
}

export default ConsultationsCountWeeklyContainer;
