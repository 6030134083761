/* eslint-disable react/no-array-index-key */
import { RefreshIcon, SearchIcon } from '@heroicons/react/solid';
import React, { useCallback, useRef } from 'react';
import useOutsideClickDetect from '../../../hooks/useOutsideClickDetect';
import { Size } from '../../../types';
import { PatientsQuery } from '../../../types/codegen/graphql';
import Button from '../../atoms/Button';

interface Props {
  searchValue: string;
  setSearchIsClosed: React.Dispatch<React.SetStateAction<boolean>>;
  searchIsClosed: boolean;
  handleSearchValueChange: React.ChangeEventHandler<HTMLInputElement>;
  handleSearchButton: () => void;
  redirectToPatientPage: (patientUuid: string) => void;
  results: PatientsQuery['patients']['items'];
  tooManyResult: boolean;
  loading: boolean;
}

function SearchBar({
  searchValue,
  searchIsClosed,
  setSearchIsClosed,
  handleSearchValueChange,
  results,
  handleSearchButton,
  redirectToPatientPage,
  tooManyResult,
  loading,
}: Props) {
  const closeDropdownSearch = useCallback(() => {
    setSearchIsClosed(true);
  }, [setSearchIsClosed]);

  const searchResults = tooManyResult ? results.slice(0, 10) : results;
  const dropdownSearchRef = useRef(null);
  useOutsideClickDetect(dropdownSearchRef, closeDropdownSearch);

  return (
    <>
      <div className="flex items-center">
        <div className="relative w-full">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <SearchIcon className="h-5 w-5" />
          </div>
          <input
            onFocus={() => setSearchIsClosed(false)}
            onChange={handleSearchValueChange}
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary-dark block w-full pl-10 p-2.5"
            placeholder="Rechercher un patient"
            value={searchValue}
            required
          />
        </div>
        <Button
          disabled={loading}
          Icon={loading ? RefreshIcon : SearchIcon}
          size={Size.S}
          classNames="ml-1"
          onClick={handleSearchButton}
        />
      </div>
      {results.length > 0 && !searchIsClosed && (
        <div className="bg-gray-100 p2 border rounded-lg overflow-scroll" ref={dropdownSearchRef}>
          {searchResults.map((x, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <p
              key={`x-${idx}`}
              className="m-2 hover:text-primary font-roboto cursor-pointer"
              onClick={() => redirectToPatientPage(x.uuid)}
            >
              {`${x.firstname} ${x.lastname}`}
            </p>
          ))}
          {tooManyResult && (
            <p className="m-4 text-sm italic font-roboto">
              Il y a un grand nombre de résultats, précise ta recherche ou clique sur le bouton
              recherche...
            </p>
          )}
        </div>
      )}
    </>
  );
}

export default SearchBar;
