import React from 'react';

import DesktopNavbar from '../../organisms/DesktopSidebar';

type Props = React.ComponentProps<typeof DesktopNavbar> & { children: React.ReactNode };

function Layout({ upperNavigation, bottomNavigation, currentUrl, children }: Props) {
  return (
    <div className="h-screen flex bg-gray-50 overflow-hidden">
      <DesktopNavbar
        currentUrl={currentUrl}
        upperNavigation={upperNavigation}
        bottomNavigation={bottomNavigation}
      />
      <div className="flex-1 min-w-0 flex flex-col overflow-scroll">
        <main>{children}</main>
      </div>
    </div>
  );
}

export default Layout;
