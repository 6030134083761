import * as yup from 'yup';
import { ModifyInvoiceFormValues } from '../pages/patient/types';
import { BAD_FIRSTNAME, BAD_LASTNAME, BAD_PRICE } from '../utils/constants';

const schema: yup.SchemaOf<ModifyInvoiceFormValues> = yup.object().shape({
  price: yup.number().typeError(BAD_PRICE).min(0, BAD_PRICE).max(2000, BAD_PRICE),
  paymentMethod: yup.string(),
  firstname: yup.string().max(50, BAD_FIRSTNAME),
  lastname: yup.string().max(50, BAD_LASTNAME),
});

export default schema;
