function downloadPDFFile(pdfBase64Str: string, filename: string) {
  const linkSource = `data:application/pdf;base64,${pdfBase64Str}`;
  const downloadLink = document.createElement('a');
  const fileName = `${filename}.pdf`;
  downloadLink.style.display = 'none';
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default downloadPDFFile;
