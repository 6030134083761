import { useLazyQuery } from '@apollo/client';

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../components/organisms/Pagination';
import SearchBar from '../../components/organisms/SearchBar';
import PatientsTableTemplate from '../../components/templates/patientsTable';
import PATIENTS_TEXT_SEARCH_QUERY from '../../graphql/patientsTextSearch';
import useDebounceValue from '../../hooks/useDebounceValue';
import { ROUTE_PATIENT } from '../../routes';
import { Patient, PatientsQuery, SortOrder } from '../../types/codegen/graphql';
import { specialCharsRegex } from '../../utils/constants';
import { notifyToasterError } from '../../utils/toaster';

const ITEMS_PER_PAGE = 10;

const ERROR_MESSAGE_QUERY =
  "Une erreur est survenue lors de la recherche de patients. Veuillez ne pas ré-itérer cette action et contacter l'administrateur";

function HomePage() {
  /**
   * Router
   */

  const history = useHistory();

  /**
   * Apollo Queries
   */

  const [searchQuery, { data: searchQueryData, loading: searchQueryLoading }] = useLazyQuery(
    PATIENTS_TEXT_SEARCH_QUERY,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        notifyToasterError(ERROR_MESSAGE_QUERY);
      },
    },
  );

  const [patientsQuery] = useLazyQuery(PATIENTS_TEXT_SEARCH_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      notifyToasterError(ERROR_MESSAGE_QUERY);
    },
  });

  /**
   * States
   */

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchIsClosed, setSearchIsClosed] = useState<boolean>(false);
  const [patientQueryResults, setPatientsQueryResults] =
    useState<PatientsQuery['patients']['items']>();
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const debouncedSearchValue = useDebounceValue<string>(searchValue, 500);

  /**
   * Handler
   */

  const handleSearchValueChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPatientsQueryResults([]);
    setNumberOfPages(0);
    setCurrentPage(0);
    if (e.target.value.length === 0) {
      setSearchValue('');
    } else if (e.target.value && !specialCharsRegex.test(e.target.value)) {
      setSearchValue(e.target.value);
    }
  };

  const goToNextPage = () => {
    if (currentPage + 1 <= numberOfPages) {
      setCurrentPage((current) => current + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((current) => current - 1);
    }
  };

  const goToPage = (page: number) => {
    if (page > 0 && page <= numberOfPages) {
      setCurrentPage(page);
    }
  };

  const redirectToPatientPage = (patientUuid: Patient['uuid']) => {
    history.push(`${ROUTE_PATIENT}/${patientUuid}`);
  };

  const handleSearchButton = async () => {
    if (debouncedSearchValue?.length === 0) {
      return;
    }

    patientsQuery({
      variables: {
        skip: 0,
        take: ITEMS_PER_PAGE,
        filters: { firstnameLastnameTextSearch: debouncedSearchValue },
        sort: { lastname: SortOrder.Asc },
      },
    }).then(({ data }) => {
      if (data?.patients) {
        const { totalItemsCount } = data.patients;
        setNumberOfPages(Math.ceil(totalItemsCount / ITEMS_PER_PAGE));
        setCurrentPage(1);
        setPatientsQueryResults(data.patients.items);
      } else {
        notifyToasterError('Erreur lors de la query "patientsQuery"');
      }
    });
  };

  /**
   * Memoized Variables
   */

  const tooManySearchResults = useMemo(() => {
    if (!searchQueryData?.patients?.items?.length) {
      return false;
    }

    return searchQueryData.patients.items.length > 10;
  }, [searchQueryData]);

  /**
   * Side-Effects
   */

  useEffect(
    () => {
      if (debouncedSearchValue?.length >= 3) {
        // call API to refresh search bar results
        searchQuery({
          variables: {
            skip: 0,
            take: 11, // take only 11 results because the searchbar will show only 10 results
            filters: { firstnameLastnameTextSearch: debouncedSearchValue },
            sort: { lastname: SortOrder.Asc },
          },
        });
      }
    },
    [debouncedSearchValue, searchQuery], // Only call effect if debounced search value changes
  );

  useEffect(() => {
    const skip = currentPage * ITEMS_PER_PAGE - 10;

    if (currentPage >= 1) {
      patientsQuery({
        variables: {
          skip,
          take: ITEMS_PER_PAGE,
          filters: { firstnameLastnameTextSearch: debouncedSearchValue },
          sort: { lastname: SortOrder.Asc },
        },
      }).then(({ data }) => {
        if (data?.patients) {
          setPatientsQueryResults(data.patients.items);
        } else {
          notifyToasterError('Erreur lors de la query "patientsQuery" lors du changement de page');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div>
      <div className="my-2 p-4">
        <SearchBar
          redirectToPatientPage={redirectToPatientPage}
          setSearchIsClosed={setSearchIsClosed}
          searchIsClosed={searchIsClosed}
          searchValue={searchValue}
          handleSearchValueChange={handleSearchValueChange}
          results={searchQueryData?.patients?.items ?? []}
          handleSearchButton={handleSearchButton}
          tooManyResult={tooManySearchResults}
          loading={searchQueryLoading}
        />
      </div>
      <div>
        <PatientsTableTemplate
          headerText="Patients"
          patients={patientQueryResults ?? []}
          subHeaderText="Résultats de la recherche"
          redirectToPatientPage={redirectToPatientPage}
        />
        {numberOfPages > 1 && (
          <div className="mt-4">
            <Pagination
              goToPage={goToPage}
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              goToPreviousPage={goToPreviousPage}
              goToNextPage={goToNextPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default HomePage;
