import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { PencilAltIcon, XIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Size, VariantColor } from '../../../types';
import Button from '../../atoms/Button';
import modifyInvoiceSchema from '../../../schemas/modifyInvoice';
import { ModifyInvoiceFormValues } from '../../../pages/patient/types';
import { notifyToasterError, notifyToasterSuccess } from '../../../utils/toaster';
import Select from '../../atoms/Select';
import Input from '../../atoms/Input';
import { PaymentMethod } from '../../../types/codegen/graphql';
import deleteObjectAttributesByKeys from '../../../utils/deleteObjectAttributesByKeys';
import stripObject from '../../../utils/stripObject';
import MODIFY_INVOICE_MUTATION from '../../../graphql/modifyInvoice';
import CONSULTATION_QUERY from '../../../graphql/consultation';
import PATIENT_QUERY from '../../../graphql/patient';

interface Props {
  setEditInvoiceMode: React.Dispatch<React.SetStateAction<boolean>>;
  paymentMethods: string[];
  invoicePaymentMethod: PaymentMethod;
  invoiceFirstname: string;
  invoiceLastname: string;
  invoicePrice: number;
  invoiceUuid: string;
  currentConsultationUuid: string;
}

function EditInvoiceForm({
  setEditInvoiceMode,
  paymentMethods,
  invoicePaymentMethod,
  invoiceFirstname,
  invoiceLastname,
  invoicePrice,
  invoiceUuid,
  currentConsultationUuid,
}: Props) {
  /**
   * Params
   */
  const { uuid: patientUuid } = useParams<{ uuid: string }>();

  /**
   * States
   */
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(invoicePaymentMethod);

  /**
   * Apollo
   */

  const [modifyInvoiceMutation] = useMutation(MODIFY_INVOICE_MUTATION);

  /**
   * Form
   */

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
  } = useForm<ModifyInvoiceFormValues>({
    resolver: yupResolver(modifyInvoiceSchema),
    defaultValues: {
      firstname: invoiceFirstname,
      lastname: invoiceLastname,
      price: invoicePrice,
      paymentMethod: invoicePaymentMethod,
    },
  });

  /**
   * Handler
   */

  const onSubmit: SubmitHandler<ModifyInvoiceFormValues> = async (formValues) => {
    try {
      const dirtyKeys = Object.keys(dirtyFields);
      const sanitizedFormValues = deleteObjectAttributesByKeys(stripObject(formValues), dirtyKeys);

      await modifyInvoiceMutation({
        variables: { modifyInvoiceInput: { uuid: invoiceUuid, ...sanitizedFormValues } },
        refetchQueries: [
          {
            query: CONSULTATION_QUERY,
            variables: {
              consultationUuid: currentConsultationUuid,
            },
          },
          { query: PATIENT_QUERY, variables: { patientUuid } },
        ],
        onCompleted: () => {
          setEditInvoiceMode(false);
          notifyToasterSuccess(`Facture modifiée avec succès`);
        },
      });
    } catch (err) {
      notifyToasterError('Erreur lors de la modification de la facture');
    }
  };

  return (
    <div className="px-6 py-4">
      <div className="mb-10">
        <Button
          classNames="mx-2"
          color={VariantColor.SECONDARY}
          Icon={XIcon}
          size={Size.S}
          onClick={() => setEditInvoiceMode(false)}
        />
        <span className="font-bold">Annuler l&apos;édition de la facture</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
        <div className="grid grid-cols-4 gap-6 my-4">
          <div className="col-span-2 sm:col-span-2 lg:col-span-2">
            <Select
              label="Moyen de Paiement"
              control={control}
              name="paymentMethod"
              selected={selectedPaymentMethod}
              setSelected={setSelectedPaymentMethod}
              values={paymentMethods}
            />
          </div>
          <div className="col-span-2 sm:col-span-2 lg:col-span-2">
            <Input
              domId="price"
              label="Prix"
              errors={errors}
              register={register('price')}
              inputType="number"
            />
          </div>
          <div className="col-span-2 sm:col-span-2 lg:col-span-2">
            <Input
              domId="lastname"
              label="Nom de la facture"
              errors={errors}
              register={register('lastname')}
              placeholder="Nom"
            />
          </div>
          <div className="col-span-2 sm:col-span-2 lg:col-span-2">
            <Input
              domId="firstname"
              label="Prénom de la facture"
              errors={errors}
              register={register('firstname')}
              placeholder="Prénom"
            />
          </div>
          <div className="col-span-full my-4 flex flex-row justify-center">
            <Button classNames="font-pacifico" Icon={PencilAltIcon} disabled={!isDirty} submit>
              Modifier la facture
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditInvoiceForm;
