import React from 'react';
import CreatePatientForm from '../organisms/CreatePatientForm';

type Props = React.ComponentProps<typeof CreatePatientForm>;

function CreatePatientTemplate({
  errors,
  handleSubmit,
  onSubmit,
  register,
  selectedGender,
  setSelectedGender,
  genders,
  control,
}: Props) {
  return (
    <CreatePatientForm
      selectedGender={selectedGender}
      setSelectedGender={setSelectedGender}
      genders={genders}
      control={control}
      errors={errors}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      register={register}
    />
  );
}

export default CreatePatientTemplate;
