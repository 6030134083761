import React from 'react';
import { StatusOfflineIcon, StatusOnlineIcon } from '@heroicons/react/solid';
import { Offline, Online } from 'react-detect-offline';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { NavigationMap } from '../../../types';

interface Props {
  upperNavigation: NavigationMap[];
  currentUrl: string;
  bottomNavigation?: NavigationMap[];
}

function DesktopNavbar({ upperNavigation, bottomNavigation, currentUrl }: Props) {
  return (
    <div className="flex flex-col w-20 z-10">
      <div className="flex-1 flex flex-col min-h-0 overflow-y-auto bg-primary">
        {/* Upper Navigation */}
        <div className="flex-1 flex flex-col">
          <div className="flex-shrink-0 border-primary border-double border-2 bg-white py-4 flex items-center justify-center">
            &#129460;
          </div>
          <nav aria-label="Sidebar" className="py-6 flex flex-col items-center space-y-3">
            {upperNavigation.map((item) => (
              <Link to={item.url} key={item.name}>
                <span
                  className={classnames(
                    'flex items-center p-4 rounded-lg text-white hover:bg-primary-dark',
                    {
                      'bg-primary-dark': item.url === currentUrl,
                    },
                  )}
                >
                  {item.icon}
                </span>
              </Link>
            ))}
            <Offline>
              <div className="flex flex-col">
                <StatusOfflineIcon className="w-8 text-red-500 mx-auto animate-spin" />
                <span className="text-sm text-red-500 font-bold">Hors ligne</span>
              </div>
            </Offline>
            <Online>
              <div className="flex flex-col">
                <StatusOnlineIcon className="w-8 text-green-500 mx-auto" />
                <span className="text-sm text-green-500 font-bold">En ligne</span>
              </div>
            </Online>
          </nav>
        </div>
        {/* Bottom Navigation */}
        <div className="flex-shrink-0 flex pb-5">
          <div className="flex-1 flex flex-col py-6 items-center space-y-3">
            {bottomNavigation &&
              bottomNavigation.map((item) => (
                <Link to={item.url} key={item.name}>
                  <span className="flex items-center p-4 rounded-lg text-white hover:bg-primary-dark">
                    {item.icon}
                  </span>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

DesktopNavbar.defaultProps = {
  bottomNavigation: undefined,
};

export default DesktopNavbar;
