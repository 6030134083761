import { PencilAltIcon } from '@heroicons/react/outline';
import React from 'react';
import { FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { ModifyPatientCaseHistoryCheckUpFormValues } from '../../../pages/patient/types';
import Button from '../../atoms/Button';
import Textarea from '../../atoms/Textarea';

interface Props {
  isDirty: boolean;
  register: UseFormRegister<ModifyPatientCaseHistoryCheckUpFormValues>;
  errors: FieldErrors;
  handleSubmit: UseFormHandleSubmit<ModifyPatientCaseHistoryCheckUpFormValues>;
  onSubmit: SubmitHandler<ModifyPatientCaseHistoryCheckUpFormValues>;
  loadingMutation: boolean;
}

function ModifyPatientCaseHistoryCheckUp({
  isDirty,
  register,
  errors,
  handleSubmit,
  onSubmit,
  loadingMutation,
}: Props) {
  return (
    <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
      <div className="shadow-lg rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="flex flex-row my-4">
            <PencilAltIcon className="h-6 w-6 text-primary mx-1" />
            <span className="text-primary font-bold text-xl">Modifier un patient</span>
          </div>
          <div className="my-4 grid grid-cols-1">
            <span className="text-secondary-dark font-bold my-4 text-md">Antécédents médicaux</span>
            <div className="col-span-full my-2">
              <Textarea
                domId="traumatic"
                register={register('traumatic')}
                errors={errors}
                label="Traumatique"
                placeholder="Traumatique"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="medical"
                register={register('medical')}
                errors={errors}
                label="Médicaux"
                placeholder="Médicaux"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="family"
                register={register('family')}
                errors={errors}
                label="Familiaux"
                placeholder="Familiaux"
              />
            </div>
          </div>
          <div className="my-4 grid grid-cols-1">
            <span className="text-secondary-dark font-bold my-4 text-md">Check-Up</span>
            <div className="col-span-full my-2">
              <Textarea
                domId="skull"
                register={register('skull')}
                errors={errors}
                label="ORL/Crâne"
                placeholder="ORL/Crâne"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="thoracic"
                register={register('thoracic')}
                errors={errors}
                label="Thoracique"
                placeholder="Thoracique"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="digestive"
                register={register('digestive')}
                errors={errors}
                label="Digestif"
                placeholder="Digestif"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="gynecology"
                register={register('gynecology')}
                errors={errors}
                label="Gynécologique"
                placeholder="Gynécologique"
              />
            </div>
          </div>
          <div className="flex flex-row mt-4 justify-center">
            <Button
              disabled={!isDirty || loadingMutation}
              classNames="font-pacifico"
              Icon={PencilAltIcon}
              submit
            >
              Modifier
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ModifyPatientCaseHistoryCheckUp;
