import { graphql } from '../types/codegen';

export default graphql(`
  query Patient($patientUuid: Uuid!) {
    patient(patientUuid: $patientUuid) {
      uuid
      createdAt
      firstname
      lastname
      firstnameWithoutDiacritics
      lastnameWithoutDiacritics
      gender
      email
      phoneNumber
      birthdate
      job
      hobbies
      address
      zipCode
      city
      remark
      caseHistory {
        traumatic
        medical
        family
      }
      checkUp {
        skull
        thoracic
        digestive
        gynecology
      }
      lastConsultationDate
      consultationsCount
      invoices {
        uuid
        date
        price
        firstname
        lastname
        paymentMethod
        medicalOffice {
          name
        }
        associatedConsultation {
          uuid
          practitioner {
            lastnameFirstname
          }
        }
      }
    }
  }
`);
