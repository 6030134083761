import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import LoginTemplate from '../../components/templates/login';
import { ROUTE_HOMEPAGE } from '../../routes';
import { LoginFormValues } from './types/index';
import loginSchema from '../../schemas/login';
import LOGIN_MUTATION from '../../graphql/login';
import { BAD_PASSWORD } from '../../utils/constants';
import ErrorPage from '../error';

function AuthPage() {
  // State
  const [redirect, setRedirect] = useState<boolean>(false);

  // Apollo
  const [login, { error, loading }] = useMutation(LOGIN_MUTATION);

  // Form
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<LoginFormValues> = async ({ email, password }) => {
    const result = await login({ variables: { input: { email, password } } });
    if (!result.data?.login) {
      setError('password', { message: BAD_PASSWORD, type: BAD_PASSWORD }, { shouldFocus: true });
    } else {
      // Store cookies and redirect
      const inSixHours = new Date(new Date().getTime() + 6 * 60 * 60 * 1000);
      Cookies.set(process.env.REACT_APP_AUTH_COOKIE as string, result.data.login, {
        expires: inSixHours,
        secure: process.env.NODE_ENV === 'production',
      });
      setRedirect(true);
    }
  };

  // Render
  if (error) {
    return <ErrorPage />;
  }

  return redirect ? (
    <Redirect to={ROUTE_HOMEPAGE} />
  ) : (
    <LoginTemplate
      loading={loading}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
    />
  );
}

export default AuthPage;
