import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';

import { Size, VariantColor } from '../../../types';
import Button from '../../atoms/Button';
import Textarea from '../../atoms/Textarea';
import { SendInvoiceFormValues } from '../../../pages/patient/types';
import { BAD_LENGTH } from '../../../utils/constants';

interface Props {
  setSendInvoiceMode: React.Dispatch<React.SetStateAction<boolean>>;
  patientEmail?: string | null;
  onSubmitSendInvoice: SubmitHandler<SendInvoiceFormValues>;
  loading: boolean;
}

function SendInvoiceForm({
  setSendInvoiceMode,
  patientEmail,
  onSubmitSendInvoice,
  loading,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SendInvoiceFormValues>({
    resolver: yupResolver(
      yup.object().shape({ customMessage: yup.string().max(900000, BAD_LENGTH) }),
    ),
    defaultValues: {
      customMessage: '',
    },
  });

  if (!patientEmail) {
    return (
      <div className="px-6 py-4">
        <div className="mb-10">
          <Button
            classNames="mx-2"
            color={VariantColor.SECONDARY}
            Icon={XIcon}
            size={Size.S}
            onClick={() => setSendInvoiceMode(false)}
          />
          <span className="font-bold">Retour</span>
          <p className="font-bold text-red-500 mb-4">
            Attention, le patient concerné ne possède pas d&apos;email. Il est donc impossible
            d&apos;effectuer l&apos;envoi de la facture par email. Veuillez renseigner un mail pour
            ce patient dans le menu de gauche
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="px-6 py-4">
      <div className="mb-10">
        <Button
          classNames="mx-2"
          color={VariantColor.SECONDARY}
          Icon={XIcon}
          size={Size.S}
          onClick={() => setSendInvoiceMode(false)}
        />
        <span className="font-bold">Annuler l&apos;envoi de la facture</span>
        <div className="my-6">
          <p>
            La facture va être envoyée par mail à{' '}
            <span className="text-red-500 font-bold">{patientEmail}</span>
          </p>
          <form
            onSubmit={handleSubmit(onSubmitSendInvoice)}
            action="#"
            method="POST"
            className="my-4"
          >
            <Textarea
              domId="customMessage"
              register={register('customMessage')}
              errors={errors}
              label="Message personnalisé"
              placeholder="Indiquez ici un message accompagnant la facture dans l'email"
            />
            <div className="flex justify-center my-4">
              <Button color={VariantColor.PRIMARY} Icon={CheckIcon} disabled={loading} submit>
                Envoyer la facture
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

SendInvoiceForm.defaultProps = {
  patientEmail: null,
};

export default SendInvoiceForm;
