import { graphql } from '../types/codegen';

export default graphql(`
  query Consultations(
    $skip: Float
    $take: Float
    $sort: ConsultationsQueryOrderByInput
    $filters: ConsultationsQueryFilters
  ) {
    consultations(skip: $skip, take: $take, sort: $sort, filters: $filters) {
      hasMore
      totalItemsCount
      items {
        uuid
        date
        practitioner {
          lastnameFirstname
        }
      }
    }
  }
`);
