import Split from 'react-split';
import { useParams } from 'react-router-dom';

import PatientPanelContainer from './containers/PatientPanelContainer';
import ConsultationPanelContainer from './containers/ConsultationPanelContainer';

function PatientPage() {
  /**
   * Router
   */

  const { uuid: patientUuid } = useParams<{ uuid: string }>();

  /**
   * Render
   */

  return (
    <Split className="split bg-gray-200 rounded-md h-screen" minSize={200} snapOffset={20}>
      <div className="p-2">
        <PatientPanelContainer patientUuid={patientUuid} />
      </div>
      <div className="p-2 h-screen">
        <ConsultationPanelContainer patientUuid={patientUuid} />
      </div>
    </Split>
  );
}

export default PatientPage;
