import { MailIcon, LockClosedIcon } from '@heroicons/react/solid';
import React from 'react';
import { UseFormHandleSubmit, FieldErrors, UseFormRegister, SubmitHandler } from 'react-hook-form';

import { LoginFormValues } from '../../../pages/auth/types';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';

interface Props {
  loading: boolean;
  register: UseFormRegister<LoginFormValues>;
  errors: FieldErrors;
  handleSubmit: UseFormHandleSubmit<LoginFormValues>;
  onSubmit: SubmitHandler<LoginFormValues>;
}

function LoginForm({ errors, onSubmit, handleSubmit, register, loading }: Props) {
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <Input
          domId="email"
          inputType="text"
          register={register('email')}
          errors={errors}
          label="Email"
          Icon={MailIcon}
          placeholder="tonEmail@email.fr"
          autoComplete="email"
        />
        <Input
          domId="password"
          inputType="password"
          register={register('password')}
          errors={errors}
          label="Mot de passe"
          Icon={LockClosedIcon}
          placeholder="********"
          autoComplete="current-password"
        />
        <div className="flex items-center justify-between" />
        <Button
          loading={loading}
          disabled={loading}
          submit
          classNames="w-full flex justify-center font-roboto"
        >
          Se connecter
        </Button>
      </form>
    </div>
  );
}

export default LoginForm;
