import { DownloadIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Size } from '../../../types';
import { PatientQuery } from '../../../types/codegen/graphql';
import formatDate from '../../../utils/formatDate';
import Button from '../../atoms/Button';

interface Props {
  invoices: NonNullable<PatientQuery['patient']>['invoices'];
  handleDownloadInvoice: (
    invoiceUuid: string,
    firstname: string,
    lastname: string,
    date: string,
  ) => void;
}

function PatientInvoicesTab({ invoices, handleDownloadInvoice }: Props) {
  return (
    <div className="rounded-md bg-white shadow overflow-y-scroll h-2/4">
      <ul className="divide-y divide-gray-200">
        {invoices.map(
          (
            {
              date,
              medicalOffice,
              paymentMethod,
              price,
              firstname,
              lastname,
              uuid,
              associatedConsultation,
            },
            idx,
          ) => (
            <li key={uuid} className={classNames('px-6 py-4', { 'bg-gray-100': idx % 2 })}>
              <Button
                classNames="mx-2"
                Icon={DownloadIcon}
                size={Size.S}
                onClick={() => handleDownloadInvoice(uuid, firstname, lastname, date)}
              />
              <span className="font-roboto text-xl">Facture </span>
              <span> du {formatDate(new Date(date))} </span>
              <span className="italic font-roboto">
                {' '}
                - {medicalOffice.name} -{' '}
                {associatedConsultation?.practitioner.lastnameFirstname || ''} - {paymentMethod} -{' '}
                {price}&#x20AC;
              </span>
            </li>
          ),
        )}
      </ul>
    </div>
  );
}

export default PatientInvoicesTab;
