const convertArrayToString = (arr: (number | string)[]): string[] =>
  arr.map((x) => (typeof x === 'number' ? x.toString() : x));

/**
 * @link https://www.zacfukuda.com/blog/pagination-algorithm
 */
function getPaginateRangesWithDot(current: number, max: number): string[] {
  if (!current || !max) return [];

  const items: (number | string)[] = [1];

  if (current === 1 && max === 1) return convertArrayToString(items);
  if (current > 4) items.push('…');

  const r = 2;
  const r1 = current - r;
  const r2 = current + r;

  for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i += 1) items.push(i);

  if (r2 + 1 < max) items.push('…');
  if (r2 < max) items.push(max);

  return convertArrayToString(items);
}

export default getPaginateRangesWithDot;
