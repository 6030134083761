import * as yup from 'yup';
import { ModifyConsultationFormValues } from '../pages/patient/types';
import {
  BAD_DATE,
  BAD_LENGTH,
  birthdateFinalFormatRegex,
  birthdateInputDateRegex,
  REQUIRED_FIELD,
} from '../utils/constants';

const schema: yup.SchemaOf<ModifyConsultationFormValues> = yup.object().shape({
  date: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('is-valid-date', BAD_DATE, (value) => {
      if (!value) {
        return false;
      }

      return birthdateInputDateRegex.test(value) || birthdateFinalFormatRegex.test(value);
    }),
  medicalOffice: yup.string().required(REQUIRED_FIELD),
  practitioner: yup.string().required(REQUIRED_FIELD),
  cause: yup.string().max(20000, BAD_LENGTH),
  remark: yup.string().max(20000, BAD_LENGTH),
  treatment: yup.string().max(20000, BAD_LENGTH),
  advice: yup.string().max(20000, BAD_LENGTH),
  evolution: yup.string().max(20000, BAD_LENGTH),
});

export default schema;
