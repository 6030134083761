import { MailIcon, PhoneIcon, PlusIcon, UserAddIcon } from '@heroicons/react/outline';
import React from 'react';
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { CreatePatientFormValues } from '../../../pages/createPatient/types';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import Textarea from '../../atoms/Textarea';

interface Props {
  register: UseFormRegister<CreatePatientFormValues>;
  errors: FieldErrors;
  handleSubmit: UseFormHandleSubmit<CreatePatientFormValues>;
  onSubmit: SubmitHandler<CreatePatientFormValues>;
  selectedGender: string;
  setSelectedGender: React.Dispatch<React.SetStateAction<string>>;
  genders: string[];
  control: Control<CreatePatientFormValues>;
}

function CreatePatientForm({
  register,
  errors,
  handleSubmit,
  onSubmit,
  selectedGender,
  setSelectedGender,
  control,
  genders,
}: Props) {
  return (
    <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
      <div className="shadow-lg rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="flex flex-row my-4">
            <UserAddIcon className="h-6 w-6 text-primary mx-1" />
            <span className="text-primary font-bold text-xl">Créer un patient</span>
          </div>
          <span className="text-secondary-dark font-bold my-4 text-md">Informations générales</span>
          <div className="grid grid-cols-4 gap-6 my-4">
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="firstname"
                autoComplete="first-name"
                inputType="text"
                register={register('firstname')}
                errors={errors}
                label="Prénom"
                placeholder="Prénom"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="lastname"
                autoComplete="last-name"
                inputType="text"
                register={register('lastname')}
                errors={errors}
                label="Nom"
                placeholder="Nom"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Select
                selected={selectedGender}
                setSelected={setSelectedGender}
                name="gender"
                label="Genre"
                control={control}
                values={genders}
                defaultValue={selectedGender}
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="birthdate"
                autoComplete="birthdate"
                inputType="date"
                register={register('birthdate')}
                errors={errors}
                label="Date de naissance"
                placeholder="JJ/MM/AAAA"
                max={new Date().toISOString().split('T')[0]}
                min="1903-01-02"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="email"
                inputType="text"
                register={register('email')}
                errors={errors}
                label="Email"
                Icon={MailIcon}
                placeholder="email@email.fr"
                autoComplete="email"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="phoneNumber"
                inputType="text"
                register={register('phoneNumber')}
                errors={errors}
                label="Numéro de téléphone"
                Icon={PhoneIcon}
                placeholder="0628789287"
              />
            </div>
            <div className="col-span-full">
              <Input
                domId="address"
                autoComplete="address"
                inputType="text"
                register={register('address')}
                errors={errors}
                label="Adresse"
                placeholder="Adresse"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="zipCode"
                inputType="text"
                register={register('zipCode')}
                errors={errors}
                label="Code Postal"
                placeholder="13006"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="city"
                inputType="text"
                register={register('city')}
                errors={errors}
                label="Ville"
                placeholder="Marseille"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="job"
                inputType="text"
                register={register('job')}
                errors={errors}
                label="Métier"
                placeholder="Développeur"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="hobbies"
                inputType="text"
                register={register('hobbies')}
                errors={errors}
                label="Activités"
                placeholder="Magicien"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="remark"
                register={register('remark')}
                errors={errors}
                label="Remarques"
                placeholder="Remarques"
              />
            </div>
          </div>
          <div className="my-4 grid grid-cols-1">
            <span className="text-secondary-dark font-bold my-4 text-md">Antécédents médicaux</span>
            <div className="col-span-full my-2">
              <Textarea
                domId="traumatic"
                register={register('traumatic')}
                errors={errors}
                label="Traumatique"
                placeholder="Traumatique"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="medical"
                register={register('medical')}
                errors={errors}
                label="Médicaux"
                placeholder="Médicaux"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="family"
                register={register('family')}
                errors={errors}
                label="Familiaux"
                placeholder="Familiaux"
              />
            </div>
          </div>
          <div className="my-4 grid grid-cols-1">
            <span className="text-secondary-dark font-bold my-4 text-md">Check-Up</span>
            <div className="col-span-full my-2">
              <Textarea
                domId="skull"
                register={register('skull')}
                errors={errors}
                label="ORL/Crâne"
                placeholder="ORL/Crâne"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="thoracic"
                register={register('thoracic')}
                errors={errors}
                label="Thoracique"
                placeholder="Thoracique"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="digestive"
                register={register('digestive')}
                errors={errors}
                label="Digestif"
                placeholder="Digestif"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="gynecology"
                register={register('gynecology')}
                errors={errors}
                label="Gynécologique"
                placeholder="Gynécologique"
              />
            </div>
          </div>
          <div className="flex flex-row mt-4 justify-center">
            <Button classNames="font-pacifico" Icon={PlusIcon} submit>
              Valider
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CreatePatientForm;
