import { MailIcon, PencilAltIcon, PhoneIcon } from '@heroicons/react/outline';
import React from 'react';
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { ModifyInfosPatientFormValues } from '../../../pages/patient/types';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import Textarea from '../../atoms/Textarea';

interface Props {
  isDirty: boolean;
  register: UseFormRegister<ModifyInfosPatientFormValues>;
  errors: FieldErrors;
  handleSubmit: UseFormHandleSubmit<ModifyInfosPatientFormValues>;
  onSubmit: SubmitHandler<ModifyInfosPatientFormValues>;
  selectedGender: string;
  setSelectedGender: React.Dispatch<React.SetStateAction<string>>;
  genders: string[];
  control: Control<ModifyInfosPatientFormValues>;
  loadingMutation: boolean;
}

function ModifyInfosPatientForm({
  register,
  errors,
  handleSubmit,
  onSubmit,
  selectedGender,
  setSelectedGender,
  control,
  genders,
  isDirty,
  loadingMutation,
}: Props) {
  return (
    <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
      <div className="shadow-lg rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="flex flex-row my-4">
            <PencilAltIcon className="h-6 w-6 text-primary mx-1" />
            <span className="text-primary font-bold text-xl">Modifier un patient</span>
          </div>
          <div className="grid grid-cols-4 gap-6 my-4">
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="firstname"
                autoComplete="first-name"
                inputType="text"
                register={register('firstname')}
                errors={errors}
                label="Prénom"
                placeholder="Prénom"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="lastname"
                autoComplete="last-name"
                inputType="text"
                register={register('lastname')}
                errors={errors}
                label="Nom"
                placeholder="Nom"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Select
                selected={selectedGender}
                setSelected={setSelectedGender}
                name="gender"
                label="Genre"
                control={control}
                values={genders}
                defaultValue={selectedGender}
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="birthdate"
                autoComplete="birthdate"
                inputType="date"
                register={register('birthdate')}
                errors={errors}
                label="Date de naissance"
                placeholder="JJ/MM/AAAA"
                max={new Date().toISOString().split('T')[0]}
                min="1903-01-02"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="email"
                inputType="text"
                register={register('email')}
                errors={errors}
                label="Email"
                Icon={MailIcon}
                placeholder="email@email.fr"
                autoComplete="email"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="phoneNumber"
                inputType="text"
                register={register('phoneNumber')}
                errors={errors}
                label="Numéro de téléphone"
                Icon={PhoneIcon}
                placeholder="0628789287"
              />
            </div>
            <div className="col-span-full">
              <Input
                domId="address"
                autoComplete="address"
                inputType="text"
                register={register('address')}
                errors={errors}
                label="Adresse"
                placeholder="Adresse"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="zipCode"
                inputType="text"
                register={register('zipCode')}
                errors={errors}
                label="Code Postal"
                placeholder="13006"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="city"
                inputType="text"
                register={register('city')}
                errors={errors}
                label="Ville"
                placeholder="Marseille"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="job"
                inputType="text"
                register={register('job')}
                errors={errors}
                label="Métier"
                placeholder="Développeur"
              />
            </div>
            <div className="col-span-2 sm:col-span-2 lg:col-span-2">
              <Input
                domId="hobbies"
                inputType="text"
                register={register('hobbies')}
                errors={errors}
                label="Activités"
                placeholder="Magicien"
              />
            </div>
            <div className="col-span-full my-2">
              <Textarea
                domId="remark"
                register={register('remark')}
                errors={errors}
                label="Remarques"
                placeholder="Remarques"
              />
            </div>
          </div>
          <div className="flex flex-row mt-4 justify-center">
            <Button
              disabled={!isDirty || loadingMutation}
              classNames="font-pacifico"
              Icon={PencilAltIcon}
              submit
            >
              Modifier
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ModifyInfosPatientForm;
