import { useApolloClient } from '@apollo/client';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTE_LOGIN } from '../../routes';

function LogoutPage() {
  const [redirect, setRedirect] = useState<boolean>(false);
  const client = useApolloClient();

  useEffect(() => {
    async function logout() {
      Cookies.remove(process.env.REACT_APP_AUTH_COOKIE as string);
      client.cache.gc();
      await client.clearStore();
      setRedirect(true);
    }

    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return redirect ? <Redirect to={ROUTE_LOGIN} /> : <div />;
}

export default LogoutPage;
