import * as yup from 'yup';
import { LoginFormValues } from '../pages/auth/types';
import { REQUIRED_FIELD, BAD_PASSWORD, BAD_EMAIL, passwordRegex } from '../utils/constants';

const schema: yup.SchemaOf<LoginFormValues> = yup.object().shape({
  email: yup.string().required(REQUIRED_FIELD).email(BAD_EMAIL),
  password: yup.string().required(REQUIRED_FIELD).matches(passwordRegex, BAD_PASSWORD),
});

export default schema;
