import { PaymentMethod } from '../types/codegen/graphql';

/**
 * Translates entity
 */

export const paymentMethodTranslate: Record<PaymentMethod, string> = {
  BANK_CHECK: 'Chèque',
  CASH: 'Espèces',
  CREDIT_CARD: 'Carte bancaire',
};

/**
 * Error message
 */

export const REQUIRED_FIELD = 'Ce champ est requis';
export const POSITIVE_NUMBER = 'Un nombre positif est requis';
export const INTEGER_NUMBER = 'Un nombre entier est requis';
export const BAD_PASSWORD = 'Mot de passe incorrect';
export const BAD_FIRSTNAME = 'Prénom incorrect';
export const BAD_LASTNAME = 'Nom incorrect';
export const BAD_EMAIL = 'Email incorrect';
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
export const specialCharsRegex = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
export const BAD_PHONE_NUMBER = 'Numéro de téléphone incorrect';
export const BAD_BIRTHDATE_DETAILS = 'La date de naissance doit être au format JJ/MM/YYYY';
export const BAD_DATE = 'La date est incorrecte';
export const BAD_PRICE = 'Prix incorrect';
export const BAD_ZIP_CODE = 'Code postal incorrect';
export const BAD_LENGTH = 'Ce champ est trop long';

/**
 * Regex
 */

export const frenchPhoneNumberRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
export const birthdateFinalFormatRegex = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/;
export const birthdateInputDateRegex = /^\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/;
export const zipCodeRegex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;

/**
 * Misc
 */

export const paymentMethods = Object.values(PaymentMethod);
