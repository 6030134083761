import * as yup from 'yup';
import { ModifyInfosPatientFormValues } from '../pages/patient/types';
import {
  BAD_BIRTHDATE_DETAILS,
  BAD_EMAIL,
  BAD_FIRSTNAME,
  BAD_LASTNAME,
  BAD_LENGTH,
  BAD_PHONE_NUMBER,
  BAD_ZIP_CODE,
  birthdateFinalFormatRegex,
  birthdateInputDateRegex,
  frenchPhoneNumberRegex,
  REQUIRED_FIELD,
  zipCodeRegex,
} from '../utils/constants';

const schema: yup.SchemaOf<ModifyInfosPatientFormValues> = yup.object().shape({
  firstname: yup.string().required(REQUIRED_FIELD).max(50, BAD_FIRSTNAME),
  lastname: yup.string().required(REQUIRED_FIELD).max(50, BAD_LASTNAME),
  gender: yup.string().oneOf(['Femme', 'Homme', 'Autre']).required(REQUIRED_FIELD),
  email: yup.string().email(BAD_EMAIL),
  phoneNumber: yup.string().test('is-valid-phone-number', BAD_PHONE_NUMBER, (value) => {
    if (!value) {
      return true;
    }

    return frenchPhoneNumberRegex.test(value);
  }),
  birthdate: yup.string().test('is-valid-birthdate', BAD_BIRTHDATE_DETAILS, (value) => {
    if (!value) {
      return true;
    }

    return birthdateInputDateRegex.test(value) || birthdateFinalFormatRegex.test(value);
  }),
  job: yup.string().max(200, BAD_LENGTH),
  hobbies: yup.string().max(20000, BAD_LENGTH),
  address: yup.string().max(1000, BAD_LENGTH),
  zipCode: yup.string().test('is-valid-zipcode', BAD_ZIP_CODE, (value) => {
    if (!value) {
      return true;
    }

    return zipCodeRegex.test(value);
  }),
  city: yup.string().max(200, BAD_LENGTH),
  remark: yup.string().max(20000, BAD_LENGTH),
});

export default schema;
