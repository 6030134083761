import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';

import Tabs from '../../../components/organisms/Tabs';
import { Gender } from '../../../types/codegen/graphql';
import reverseObjectMap from '../../../utils/reverseObjectMap';
import { ModifyInfosPatientFormValues, ModifyPatientCaseHistoryCheckUpFormValues } from '../types';
import modifyInfosPatientSchema from '../../../schemas/modifyPatientInfosForm';
import PatientStatisticsTab from '../../../components/organisms/PatientStatisticsTab';
import ModifyPatientCaseHistoryCheckUp from '../../../components/organisms/ModifyPatientCaseHistoryCheckUpForm';
import ModifyInfosPatientForm from '../../../components/organisms/ModifyInfosPatientForm';
import PATIENT_QUERY from '../../../graphql/patient';
import PATIENT_NUMBER_CONSULTATION_PER_MONTHS_STATS from '../../../graphql/patientNumberConsultationLastCurrentYearStats';
import MODIFY_PATIENT_QUERY from '../../../graphql/modifyPatient';
import DOWNLOAD_INVOICE_QUERY from '../../../graphql/downloadInvoice';
import modifyPatientCaseHistoryCheckUpSchema from '../../../schemas/modifyPatientCaseHistoryCheckUpForm';
import {
  notifyToasterError,
  notifyToasterInfo,
  notifyToasterSuccess,
} from '../../../utils/toaster';
import deleteObjectAttributesByKeys from '../../../utils/deleteObjectAttributesByKeys';
import stripObject from '../../../utils/stripObject';
import LoadingPage from '../../loading';
import ErrorPage from '../../error';
import PatientInvoicesTab from '../../../components/organisms/PatientInvoicesTab';
import downloadPDFFile from '../../../utils/downloadPdfFile';
import formatDate from '../../../utils/formatDate';

interface Props {
  patientUuid: string;
}

// Constants
const INFOS_GENERALES_TAB = 'Infos Générales';
const CHECKUP_CASE_HISTORY_TAB = 'Check-up/Antécédents';
const STATISTICS_TAB = 'Statistiques';
const INVOICES_TAB = 'Factures';
const tabs = [INFOS_GENERALES_TAB, CHECKUP_CASE_HISTORY_TAB, STATISTICS_TAB, INVOICES_TAB];
const genders = ['Femme', 'Homme', 'Autre'];
const gendersMap: Record<string, Gender> = {
  Femme: Gender.Female,
  Homme: Gender.Male,
  Autre: Gender.Other,
};

function PatientPanelContainer({ patientUuid }: Props) {
  /**
   * States
   */
  const [currentTab, setCurrentTab] = useState(INFOS_GENERALES_TAB);
  const [selectedGender, setSelectedGender] = useState(genders[0]);

  /**
   * Apollo
   */
  const {
    data: patientQueryData,
    loading: patientQueryLoading,
    error: patientQueryError,
  } = useQuery(PATIENT_QUERY, {
    variables: { patientUuid },
  });

  const {
    data: patientNumberConsultationsPerMonthsYearStatsData,
    loading: patientNumberConsultationsPerMonthsYearStatsLoading,
    error: patientNumberConsultationsPerMonthsYearStatsError,
  } = useQuery(PATIENT_NUMBER_CONSULTATION_PER_MONTHS_STATS, {
    variables: {
      patientUuid,
      currentYear: new Date().getFullYear(),
      lastYear: new Date().getFullYear() - 1,
    },
  });

  const [downloadInvoiceQuery] = useLazyQuery(DOWNLOAD_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [modifyPatientMutation, { loading: modifyPatientMutationLoading }] = useMutation(
    MODIFY_PATIENT_QUERY,
    {
      refetchQueries: [{ query: PATIENT_QUERY, variables: { patientUuid } }],
    },
  );

  /**
   * Forms
   */
  const {
    register: registerModifyInfosPatientForm,
    handleSubmit: handleSubmitModifyInfosPatientForm,
    formState: {
      errors: errorsModifyInfosPatientForm,
      isDirty: isDirtyModifyInfosPatientForm,
      dirtyFields: dirtyFieldsModifyInfosPatientForm,
    },
    control: controlModifyInfosPatientForm,
    reset: resetModifyInfosPatientForm,
  } = useForm<ModifyInfosPatientFormValues>({
    resolver: yupResolver(modifyInfosPatientSchema),
    defaultValues: useMemo(() => {
      const gender = (
        patientQueryData?.patient?.gender
          ? reverseObjectMap(gendersMap)[patientQueryData.patient.gender]
          : genders[0]
      ) as Gender;

      return {
        firstname: patientQueryData?.patient?.firstname ?? '',
        lastname: patientQueryData?.patient?.lastname ?? '',
        gender,
        email: patientQueryData?.patient?.email ?? '',
        phoneNumber: patientQueryData?.patient?.phoneNumber ?? '',
        birthdate: patientQueryData?.patient?.birthdate
          ? new Date(patientQueryData.patient.birthdate).toISOString().split('T')[0]
          : '',
        job: patientQueryData?.patient?.job ?? '',
        hobbies: patientQueryData?.patient?.hobbies ?? '',
        address: patientQueryData?.patient?.address ?? '',
        zipCode: patientQueryData?.patient?.zipCode ?? '',
        city: patientQueryData?.patient?.city ?? '',
        remark: patientQueryData?.patient?.remark ?? '',
      };
    }, [patientQueryData]),
  });

  const {
    register: registerModifyPatientCaseHistoryCheckUpForm,
    handleSubmit: handleSubmitModifyPatientCaseHistoryCheckUpForm,
    formState: {
      errors: errorsModifyPatientCaseHistoryCheckUpForm,
      isDirty: isDirtyModifyPatientCaseHistoryCheckUpForm,
      dirtyFields: dirtyFieldsModifyPatientCaseHistoryCheckUpForm,
    },
    reset: resetModifyPatientCaseHistoryCheckUpForm,
  } = useForm<ModifyPatientCaseHistoryCheckUpFormValues>({
    resolver: yupResolver(modifyPatientCaseHistoryCheckUpSchema),
    defaultValues: useMemo(() => {
      return {
        traumatic: patientQueryData?.patient?.caseHistory?.traumatic ?? '',
        medical: patientQueryData?.patient?.caseHistory?.medical ?? '',
        family: patientQueryData?.patient?.caseHistory?.family ?? '',
        skull: patientQueryData?.patient?.checkUp?.skull ?? '',
        thoracic: patientQueryData?.patient?.checkUp?.thoracic ?? '',
        digestive: patientQueryData?.patient?.checkUp?.digestive ?? '',
        gynecology: patientQueryData?.patient?.checkUp?.gynecology ?? '',
      };
    }, [patientQueryData]),
  });

  /**
   * Handlers
   */

  const handleDownloadInvoice = async (
    invoiceUuid: string,
    firstname: string,
    lastname: string,
    date: string,
  ) => {
    try {
      notifyToasterInfo('Téléchargement de la facture en cours ...');
      const requestResult = await downloadInvoiceQuery({
        variables: { invoiceUuid },
        fetchPolicy: 'no-cache',
      });

      if (!requestResult?.data?.downloadInvoice) {
        throw new Error('front: error during download invoice');
      }

      const base64PdfFile = requestResult.data.downloadInvoice;
      const filename = `${firstname} ${lastname} Facture du ${formatDate(new Date(date))}`
        .replace(/[^a-z0-9]/gi, '_')
        .toLowerCase();

      downloadPDFFile(base64PdfFile, filename);
    } catch (err) {
      notifyToasterError(
        `Erreur lors du téléchargement de la facture ${invoiceUuid}. Veuillez ne pas réessayer et contacter l'administrateur de l'application`,
      );
    }
  };

  /**
   * Submit Form Handlers
   */

  const onSubmitModifyInfosPatientForm: SubmitHandler<ModifyInfosPatientFormValues> = async (
    values,
  ) => {
    try {
      const modifyPatientInput = deleteObjectAttributesByKeys(
        stripObject({
          ...values,
          gender: gendersMap[values.gender],
        }),
        Object.keys(dirtyFieldsModifyInfosPatientForm),
      );

      await modifyPatientMutation({
        variables: {
          patientUuid,
          modifyPatientInput,
        },
      });
      notifyToasterSuccess('Patient modifié avec succès');
    } catch (err) {
      notifyToasterError(
        "Erreur lors de la requête du modification du patient, veuillez contacter l'administrateur et ne pas ré-iterer votre requête",
      );
    }
  };

  const onSubmitModifyPatientCaseHistoryCheckUpForm: SubmitHandler<
    ModifyPatientCaseHistoryCheckUpFormValues
  > = async (values) => {
    try {
      const caseHistory = deleteObjectAttributesByKeys(
        stripObject({
          traumatic: values.traumatic,
          family: values.family,
          medical: values.medical,
        }),
        Object.keys(dirtyFieldsModifyPatientCaseHistoryCheckUpForm),
      );

      const checkUp = deleteObjectAttributesByKeys(
        stripObject({
          digestive: values.digestive,
          gynecology: values.gynecology,
          skull: values.skull,
          thoracic: values.thoracic,
        }),
        Object.keys(dirtyFieldsModifyPatientCaseHistoryCheckUpForm),
      );

      await modifyPatientMutation({
        variables: {
          patientUuid,
          modifyPatientInput: {
            ...(Object.keys(checkUp).length > 0 ? { checkUp } : {}),
            ...(Object.keys(caseHistory).length > 0 ? { caseHistory } : {}),
          },
        },
      });

      notifyToasterSuccess('Patient modifié avec succès');
    } catch (err) {
      notifyToasterError(
        "Erreur lors de la requête du modification du patient, veuillez contacter l'administrateur et ne pas ré-iterer votre requête",
      );
    }
  };

  /**
   * Side-Effects
   */

  useEffect(() => {
    if (patientQueryData?.patient) {
      const gender = (
        patientQueryData?.patient?.gender
          ? reverseObjectMap(gendersMap)[patientQueryData.patient.gender]
          : genders[0]
      ) as Gender;

      setSelectedGender(gender);
      resetModifyInfosPatientForm({
        firstname: patientQueryData?.patient?.firstname ?? '',
        lastname: patientQueryData?.patient?.lastname ?? '',
        gender,
        email: patientQueryData?.patient?.email ?? '',
        phoneNumber: patientQueryData?.patient?.phoneNumber ?? '',
        birthdate: patientQueryData?.patient?.birthdate
          ? new Date(patientQueryData.patient.birthdate).toISOString().split('T')[0]
          : '',
        job: patientQueryData?.patient?.job ?? '',
        hobbies: patientQueryData?.patient?.hobbies ?? '',
        address: patientQueryData?.patient?.address ?? '',
        zipCode: patientQueryData?.patient?.zipCode ?? '',
        city: patientQueryData?.patient?.city ?? '',
        remark: patientQueryData?.patient?.remark ?? '',
      });
      resetModifyPatientCaseHistoryCheckUpForm({
        traumatic: patientQueryData?.patient?.caseHistory?.traumatic ?? '',
        medical: patientQueryData?.patient?.caseHistory?.medical ?? '',
        family: patientQueryData?.patient?.caseHistory?.family ?? '',
        skull: patientQueryData?.patient?.checkUp?.skull ?? '',
        thoracic: patientQueryData?.patient?.checkUp?.thoracic ?? '',
        digestive: patientQueryData?.patient?.checkUp?.digestive ?? '',
        gynecology: patientQueryData?.patient?.checkUp?.gynecology ?? '',
      });
    }
  }, [patientQueryData, resetModifyInfosPatientForm, resetModifyPatientCaseHistoryCheckUpForm]);

  /**
   * Render
   */

  if (patientQueryLoading || patientNumberConsultationsPerMonthsYearStatsLoading) {
    return <LoadingPage />;
  }

  if (
    patientQueryError ||
    patientNumberConsultationsPerMonthsYearStatsError ||
    !patientQueryData?.patient ||
    !patientNumberConsultationsPerMonthsYearStatsData?.patient
      ?.numberConsultationsPerMonthCurrentYear ||
    !patientNumberConsultationsPerMonthsYearStatsData?.patient?.numberConsultationsPerMonthLastYear
  ) {
    return <ErrorPage />;
  }

  return (
    <>
      <Prompt
        when={isDirtyModifyInfosPatientForm || isDirtyModifyPatientCaseHistoryCheckUpForm}
        message={`Es-tu sûr(e) de vouloir quitter cette page ? Des modifications sont en cours dans les onglets : ${
          isDirtyModifyInfosPatientForm ? INFOS_GENERALES_TAB : ''
        } ${isDirtyModifyPatientCaseHistoryCheckUpForm ? CHECKUP_CASE_HISTORY_TAB : ''}`}
      />
      <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {currentTab === INFOS_GENERALES_TAB && (
        <ModifyInfosPatientForm
          control={controlModifyInfosPatientForm}
          errors={errorsModifyInfosPatientForm}
          handleSubmit={handleSubmitModifyInfosPatientForm}
          register={registerModifyInfosPatientForm}
          genders={genders}
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
          onSubmit={onSubmitModifyInfosPatientForm}
          isDirty={isDirtyModifyInfosPatientForm}
          loadingMutation={modifyPatientMutationLoading}
        />
      )}
      {currentTab === CHECKUP_CASE_HISTORY_TAB && (
        <ModifyPatientCaseHistoryCheckUp
          errors={errorsModifyPatientCaseHistoryCheckUpForm}
          handleSubmit={handleSubmitModifyPatientCaseHistoryCheckUpForm}
          isDirty={isDirtyModifyPatientCaseHistoryCheckUpForm}
          register={registerModifyPatientCaseHistoryCheckUpForm}
          onSubmit={onSubmitModifyPatientCaseHistoryCheckUpForm}
          loadingMutation={modifyPatientMutationLoading}
        />
      )}
      {currentTab === STATISTICS_TAB && (
        <PatientStatisticsTab
          consultationsCount={patientQueryData.patient.consultationsCount}
          lastConsultationDate={patientQueryData.patient.lastConsultationDate ?? null}
          numberConsultationsPerMonthCurrentYear={
            patientNumberConsultationsPerMonthsYearStatsData.patient
              .numberConsultationsPerMonthCurrentYear
          }
          numberConsultationsPerMonthLastYear={
            patientNumberConsultationsPerMonthsYearStatsData.patient
              .numberConsultationsPerMonthLastYear
          }
        />
      )}
      {currentTab === INVOICES_TAB && (
        <PatientInvoicesTab
          invoices={patientQueryData.patient.invoices}
          handleDownloadInvoice={handleDownloadInvoice}
        />
      )}
    </>
  );
}

export default PatientPanelContainer;
