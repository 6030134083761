import { Prompt } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MutationTuple, useQuery } from '@apollo/client';
import { DocumentAddIcon, ExclamationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateConsultationFormValues } from '../../../pages/patient/types';
import createConsultationSchema from '../../../schemas/createConsultation';
import PATIENT_QUERY from '../../../graphql/patient';
import {
  ConsultationInput,
  CreateConsultationMutation,
  CreateConsultationMutationVariables,
  GetMedicalOfficePractitionersQuery,
  PaymentMethod,
} from '../../../types/codegen/graphql';
import { notifyToasterError, notifyToasterSuccess } from '../../../utils/toaster';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import Textarea from '../../atoms/Textarea';
import { VariantColor } from '../../../types';
import Toggle from '../../atoms/Toggle';
import LoadingPage from '../../../pages/loading';
import ErrorPage from '../../../pages/error';

interface Props {
  handleBackButton: () => void;
  patientUuid: string;
  paymentMethods: string[];
  medicalOffices: string[];
  practitioners: string[];
  getMedicalOfficesPractitionersData: GetMedicalOfficePractitionersQuery;
  createConsultationMutation: MutationTuple<
    CreateConsultationMutation,
    CreateConsultationMutationVariables
  >['0'];
}

function CreateConsultationForm({
  handleBackButton,
  patientUuid,
  paymentMethods,
  medicalOffices,
  practitioners,
  getMedicalOfficesPractitionersData,
  createConsultationMutation,
}: Props) {
  /**
   * Apollo
   */
  const {
    data: patientQueryData,
    loading: patientQueryLoading,
    error: patientQueryError,
  } = useQuery(PATIENT_QUERY, {
    variables: { patientUuid },
  });

  /**
   * States
   */

  const [selectedMedicalOffice, setSelectedMedicalOffice] = useState<string>('');
  const [selectedPractitioner, setSelectedPractitioner] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(paymentMethods[0]);
  const [showQuitWarning, setShowQuitWarning] = useState<boolean>(false);
  const [generateAssociatedInvoice, setGenerateAssociatedInvoice] = useState<boolean>(false);
  const [sendInvoiceToThePatientByMail, setSendInvoiceToThePatientByMail] =
    useState<boolean>(false);

  /**
   * Forms
   */

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset: resetForm,
    control,
    setValue: setFormValue,
  } = useForm<CreateConsultationFormValues>({
    resolver: yupResolver(createConsultationSchema),
    defaultValues: {
      price: 60,
      date: new Date().toISOString().split('T')[0],
      cause: 'Topo : \nChrono/FD : \nFA : \nFAA : \nSA : \nPEC : \n',
    },
  });

  /**
   * Side-Effects
   */

  useEffect(() => {
    // Set default values for the create consultation form
    if (medicalOffices?.[0] && practitioners?.[0]) {
      setSelectedMedicalOffice(medicalOffices[0]);
      setSelectedPractitioner(practitioners[0]);
      setSelectedPaymentMethod(paymentMethods[0]);
      setFormValue('medicalOffice', medicalOffices[0]);
      setFormValue('practitioner', practitioners[0]);
      setFormValue('paymentMethod', paymentMethods[0]);
    }

    if (patientQueryData?.patient) {
      setFormValue('invoiceFirstname', patientQueryData.patient.firstname);
      setFormValue('invoiceLastname', patientQueryData.patient.lastname);
    }
  }, [medicalOffices, practitioners, setFormValue, paymentMethods, patientQueryData]);

  /**
   * Submit handlers forms
   */

  const onSubmit: SubmitHandler<CreateConsultationFormValues> = async ({
    practitioner,
    medicalOffice,
    paymentMethod,
    invoiceFirstname,
    invoiceLastname,
    ...formValues
  }) => {
    try {
      if (getMedicalOfficesPractitionersData) {
        const practitionerUuid = getMedicalOfficesPractitionersData.practitioners.find(
          (x) => x.lastnameFirstname === practitioner,
        )?.uuid;

        const medicalOfficeUuid = getMedicalOfficesPractitionersData.medicalOffices.find(
          (x) => x.name === medicalOffice,
        )?.uuid;

        if (!medicalOfficeUuid || !practitionerUuid) {
          throw new Error('cant find medicalOfficeUuid or practitionerUuid');
        }

        if (
          generateAssociatedInvoice &&
          (!invoiceFirstname || !invoiceLastname || !paymentMethod || !formValues.price)
        ) {
          notifyToasterError(
            'Impossible de créer la consultation : Si l\'option "Génerer une facture" est activée, les champs "Prénom", "Nom", "Prix", "Moyen de paiement" de la facture doivent être renseignés.',
          );
          return;
        }

        const mutationPayload: ConsultationInput = {
          patientUuid,
          medicalOfficeUuid,
          practitionerUuid,
          generateAssociatedInvoice,
          sendInvoiceToThePatientByMail,
          paymentMethod: paymentMethod as PaymentMethod,
          ...(invoiceFirstname ? { firstname: invoiceFirstname } : {}),
          ...(invoiceLastname ? { lastname: invoiceLastname } : {}),
          ...formValues,
        };

        await createConsultationMutation({ variables: { consultationInput: mutationPayload } });

        notifyToasterSuccess(`Consultation créée avec succès`);
        resetForm();
        handleBackButton();
      }
    } catch (err) {
      notifyToasterError(
        "Erreur lors de la création de la consultation. Malgré tout, la consultation est surement créée, veuillez vérifier avant de retenter l'opération. Veuillez contacter l'administrateur",
        50000,
      );
    }
  };

  if (patientQueryLoading) {
    return <LoadingPage />;
  }

  if (patientQueryError || !patientQueryData?.patient) {
    return <ErrorPage />;
  }

  /**
   * Render
   */

  return (
    <>
      <Prompt
        when={isDirty}
        message="Une consultation est en cours de création. Es-tu sûr de vouloir quitter cette page ? "
      />
      <div className="mb-2 mx-auto text-center">
        {!showQuitWarning && (
          <Button
            Icon={ArrowLeftIcon}
            onClick={!isDirty ? handleBackButton : () => setShowQuitWarning(true)}
          >
            Retour
          </Button>
        )}
        {isDirty && showQuitWarning && (
          <Button Icon={ArrowLeftIcon} onClick={handleBackButton} color={VariantColor.SECONDARY}>
            Je veux quitter cette page
          </Button>
        )}
        {showQuitWarning && (
          <p className="font-bold mt-2">
            Attention, une modification est en cours. Veux-tu vraiment quitter cette page ?{' '}
          </p>
        )}
      </div>
      <div className="bg-white rounded-md shadow-lg">
        <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex flex-row my-4">
              <DocumentAddIcon className="h-6 w-6 text-primary mx-1" />
              <span className="text-primary font-bold text-xl">Créer une consultation</span>
            </div>
            <div className="grid grid-cols-4 gap-6 my-4">
              <div className="col-span-full">
                <Input
                  domId="date"
                  autoComplete="date"
                  inputType="date"
                  register={register('date')}
                  errors={errors}
                  label="Date"
                  placeholder="JJ/MM/AAAA"
                  max={new Date().toISOString().split('T')[0]}
                  min="1903-01-02"
                />
              </div>
              <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                <Select
                  label="Praticien"
                  control={control}
                  name="practitioner"
                  selected={selectedPractitioner}
                  setSelected={setSelectedPractitioner}
                  values={practitioners}
                />
              </div>
              <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                <Select
                  label="Cabinet"
                  control={control}
                  name="medicalOffice"
                  selected={selectedMedicalOffice}
                  setSelected={setSelectedMedicalOffice}
                  values={medicalOffices}
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="cause"
                  register={register('cause')}
                  errors={errors}
                  label="Motif de consultation"
                  placeholder="Motif de consultation"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="remark"
                  register={register('remark')}
                  errors={errors}
                  label="Remarques"
                  placeholder="Remarques"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="treatment"
                  register={register('treatment')}
                  errors={errors}
                  label="Traitements"
                  placeholder="Traitements"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="advice"
                  register={register('advice')}
                  errors={errors}
                  label="Conseils"
                  placeholder="Conseils"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="evolution"
                  register={register('evolution')}
                  errors={errors}
                  label="Évolutions"
                  placeholder="Evolutions"
                />
              </div>

              <div className="col-span-full my-2">
                {!patientQueryData.patient.email && (
                  <p className="font-bold text-red-500 mb-4">
                    Attention, le patient concerné ne possède pas d&apos;email. Il est donc
                    impossible d&apos;effectuer l&apos;envoi de la facture par email.
                  </p>
                )}
                <div className="flex flex-row">
                  <Toggle
                    enabled={generateAssociatedInvoice}
                    setEnabled={setGenerateAssociatedInvoice}
                  />
                  <span className="ml-4 font-bold">Génerer une facture</span>
                </div>
                {!generateAssociatedInvoice && (
                  <div className="flex flex-row my-2">
                    <ExclamationCircleIcon className="w-10 text-red-500 mr-4" />
                    <p className="my-2 text-md font-bold">
                      Attention, il faut génerer une facture pour la prise en compte de la
                      consultation dans la comptabilité de l&apos;application.
                    </p>
                  </div>
                )}
              </div>
              {generateAssociatedInvoice && (
                <>
                  <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                    <Select
                      label="Moyen de Paiement"
                      control={control}
                      name="paymentMethod"
                      selected={selectedPaymentMethod}
                      setSelected={setSelectedPaymentMethod}
                      values={paymentMethods}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                    <Input
                      domId="price"
                      label="Prix"
                      errors={errors}
                      register={register('price')}
                      inputType="number"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                    <Input
                      domId="invoiceLastname"
                      label="Nom de la facture"
                      errors={errors}
                      register={register('invoiceLastname')}
                      placeholder="Nom"
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                    <Input
                      domId="invoiceFirstname"
                      label="Prénom de la facture"
                      errors={errors}
                      register={register('invoiceFirstname')}
                      placeholder="Prénom"
                    />
                  </div>
                  <div className="col-span-full my-2 flex flex-row">
                    {patientQueryData.patient.email && (
                      <>
                        <Toggle
                          setEnabled={setSendInvoiceToThePatientByMail}
                          enabled={sendInvoiceToThePatientByMail}
                        />
                        <span className="font-bold ml-4">
                          Envoyer la facture par email au patient{' '}
                          {`(Email du patient : ${patientQueryData.patient.email})`}
                        </span>
                      </>
                    )}
                  </div>
                  {sendInvoiceToThePatientByMail && (
                    <div className="col-span-full my-2">
                      <Textarea
                        domId="customMessage"
                        register={register('customMessage')}
                        errors={errors}
                        label="Message personnalisé"
                        placeholder="Indiquez ici un message accompagnant la facture dans l'email"
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="flex flex-row mt-4 justify-center">
              <Button classNames="font-pacifico" Icon={PlusIcon} submit>
                Créer
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateConsultationForm;
