import { graphql } from '../types/codegen';

export default graphql(`
  mutation ModifyConsultation(
    $modifyConsultationInput: ModifyConsultationInput!
    $consultationUuid: Uuid!
  ) {
    modifyConsultation(
      modifyConsultationInput: $modifyConsultationInput
      consultationUuid: $consultationUuid
    ) {
      uuid
    }
  }
`);
