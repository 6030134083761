/**
 * @description Filter the properties/attributes of an object by deleting them
 * @param {object} obj Object to filters attributes
 * @param {string[]} keysToKeep All keys included in this array will be keeped
 * @returns {object} the filtered object
 */
const deleteObjectAttributesByKeys = (obj: object, keysToKeep: string[]): object =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.fromEntries(Object.entries(obj).filter(([key, _]) => keysToKeep.includes(key)));

export default deleteObjectAttributesByKeys;
