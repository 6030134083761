import { graphql } from '../types/codegen';

export default graphql(`
  query getMedicalOfficePractitioners {
    practitioners {
      uuid
      lastnameFirstname
      linkedUserUuid
    }
    medicalOffices {
      uuid
      name
    }
  }
`);
