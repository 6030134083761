import { graphql } from '../types/codegen';

export default graphql(`
  query me {
    me {
      email
      userRole
      uuid
    }
  }
`);
