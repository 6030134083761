import { MouseEvent } from 'react';
import classnames from 'classnames';
import { BeatLoader } from 'react-spinners';
import { Size, VariantColor } from '../../../types';

interface Props {
  size?: Size;
  color?: VariantColor;
  submit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  classNames?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  iconClassNames?: string;
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

function Button({
  classNames,
  iconClassNames,
  size,
  color,
  disabled,
  onClick,
  children,
  submit,
  loading,
  Icon,
}: Props) {
  const padding: Record<Size, string> = {
    s: 'px-3 py-2',
    m: 'px-4 py-2',
    l: 'px-4 py-2',
  };

  const fontSize: Record<Size, string> = {
    s: 'text-sm leading-4',
    m: 'text-sm',
    l: 'text-base',
  };

  const variant: Record<VariantColor, string> = {
    primary:
      'border-transparent shadow-sm text-white bg-primary hover:bg-primary-dark focus:ring-primary',
    secondary:
      'border-transparent text-white bg-secondary hover:bg-secondary-dark focus:ring-secondary',
    info: 'border-transparent text-white bg-info hover:bg-info-dark focus:ring-info',
    white: 'border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary',
    disabled: 'border-transparent text-white bg-disabled focus:ring-white',
  };

  return (
    <button
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      className={classnames(
        'inline-flex items-center border font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2',
        padding[size!],
        fontSize[size!],
        disabled ? variant.disabled : variant[color!],
        { 'cursor-not-allowed': disabled },
        classNames,
      )}
    >
      {loading ? (
        <BeatLoader
          loading
          size={5}
          color={
            color === VariantColor.WHITE
              ? `#${process.env.REACT_APP_PRE_BUILD_PRIMARY_COLOR}`
              : 'white'
          }
        />
      ) : (
        <>
          {Icon && (
            <Icon className={classnames({ '-ml-1 mr-3': children }, 'w-5', iconClassNames)} />
          )}
          {children}
        </>
      )}
    </button>
  );
}

Button.defaultProps = {
  size: Size.M,
  color: VariantColor.PRIMARY,
  loading: false,
  children: '',
  classNames: '',
  iconClassNames: '',
  disabled: false,
  submit: false,
  onClick: undefined,
  Icon: undefined,
};

export default Button;
