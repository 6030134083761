import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import SEND_INVOICE_BY_MAIL from '../../../graphql/sendInvoiceByMail';
import ConsultationsList from '../../../components/organisms/ConsultationsList';
import CreateConsultationForm from '../../../components/organisms/CreateConsultationForm';
import EditConsultationForm from '../../../components/organisms/EditConsultationForm';
import PAGINATED_CONSULTATIONS_QUERY from '../../../graphql/paginatedConsultations';
import PATIENT_NUMBER_CONSULTATION_PER_MONTHS_STATS from '../../../graphql/patientNumberConsultationLastCurrentYearStats';
import GET_MEDICAL_OFFICE_PRACTITIONERS from '../../../graphql/getMedicalOfficePractitioners';
import CREATE_CONSULTATION_MUTATION from '../../../graphql/createConsultation';
import CREATE_INVOICE_MUTATION from '../../../graphql/createInvoice';
import MODIFY_CONSULTATION_MUTATION from '../../../graphql/modifyConsultation';
import ME_QUERY from '../../../graphql/me';
import PATIENT_QUERY from '../../../graphql/patient';
import { SortOrder } from '../../../types/codegen/graphql';
import ErrorPage from '../../error';
import LoadingPage from '../../loading';
import { paymentMethods } from '../../../utils/constants';

interface Props {
  patientUuid: string;
}

function ConsultationPanelContainer({ patientUuid }: Props) {
  /**
   * States
   */

  const [createConsultationMode, setCreateConsultationMode] = useState<boolean>(false);
  const [editConsultationMode, setEditConsultationMode] = useState<string>(''); // represents current consultation uuid to view
  const [consultationsListMode, setConsultationsListMode] = useState<boolean>(true);

  /**
   * Apollo
   */

  // Queries

  const {
    data: getMedicalOfficesPractitionersData,
    loading: getMedicalOfficesPractitionersLoading,
    error: getMedicalOfficesPractitionersError,
  } = useQuery(GET_MEDICAL_OFFICE_PRACTITIONERS);

  const { data: meData, loading: meLoading, error: meError } = useQuery(ME_QUERY);

  const {
    data: consultationsQueryData,
    loading: consultationsQueryLoading,
    error: consultationsQueryError,
    fetchMore: fetchMoreConsultations,
  } = useQuery(PAGINATED_CONSULTATIONS_QUERY, {
    variables: {
      filters: { patientUuids: [patientUuid] },
      sort: { date: SortOrder.Desc },
      skip: 0,
      take: 10,
    },
  });

  // Mutations

  const [createConsultationMutation, { loading: createConsultationLoading }] = useMutation(
    CREATE_CONSULTATION_MUTATION,
    {
      refetchQueries: [
        {
          query: PAGINATED_CONSULTATIONS_QUERY,
          variables: {
            filters: { patientUuids: [patientUuid] },
            sort: { date: SortOrder.Desc },
            skip: 0,
            take: 10,
          },
        },
        { query: PATIENT_QUERY, variables: { patientUuid } },
        {
          query: PATIENT_NUMBER_CONSULTATION_PER_MONTHS_STATS,
          variables: {
            patientUuid,
            currentYear: new Date().getFullYear(),
            lastYear: new Date().getFullYear() - 1,
          },
        },
      ],
    },
  );

  const [modifyConsultationMutation, { loading: modifyConsultationLoading }] = useMutation(
    MODIFY_CONSULTATION_MUTATION,
  );

  const [createInvoiceMutation, { loading: createInvoiceLoading }] =
    useMutation(CREATE_INVOICE_MUTATION);

  const [sendInvoiceByMailMutation, { loading: sendInvoiceByMailLoading }] =
    useMutation(SEND_INVOICE_BY_MAIL);

  /**
   * Memoize
   */

  const medicalOffices: string[] = useMemo(() => {
    if (!getMedicalOfficesPractitionersData?.medicalOffices) {
      return [];
    }

    return getMedicalOfficesPractitionersData.medicalOffices.map((x) => x.name);
  }, [getMedicalOfficesPractitionersData]);

  const practitioners: string[] = useMemo(() => {
    if (!getMedicalOfficesPractitionersData?.practitioners || !meData?.me) {
      return [];
    }

    // Don't show other practitioners if the current user logged is not an admin user
    if (meData.me.userRole !== 'admin') {
      const { uuid: userUuid } = meData.me;
      const practitionerToShow = getMedicalOfficesPractitionersData.practitioners.find(
        (x) => x.linkedUserUuid === userUuid,
      );

      return practitionerToShow ? [practitionerToShow.lastnameFirstname] : [];
    }

    return getMedicalOfficesPractitionersData.practitioners
      .filter((x) => x.lastnameFirstname !== 'Bertrand Mathilde') // TODO: Bad Front fixup : Filter out useless "Bertrand Mathilde" practitioner value
      .map((x) => x.lastnameFirstname);
  }, [getMedicalOfficesPractitionersData, meData]);

  /**
   * Handler
   */

  const fetchMoreConsultationsHandler = useCallback(() => {
    if (consultationsQueryData && consultationsQueryData.consultations.hasMore) {
      fetchMoreConsultations({
        variables: {
          skip: consultationsQueryData.consultations.items.length,
        },
      });
    }
  }, [consultationsQueryData, fetchMoreConsultations]);

  const handleGoToConsultationsListMode = () => {
    setConsultationsListMode(true);
    setEditConsultationMode('');
    setCreateConsultationMode(false);
  };

  const handleGoToCreateConsultationMode = () => {
    setCreateConsultationMode(true);
    setConsultationsListMode(false);
    setEditConsultationMode('');
  };

  const handleGoToEditConsultationMode = (consultationUuid: string) => {
    setEditConsultationMode(consultationUuid);
    setConsultationsListMode(false);
    setCreateConsultationMode(false);
  };

  /**
   * Render
   */

  if (
    consultationsQueryLoading ||
    getMedicalOfficesPractitionersLoading ||
    createConsultationLoading ||
    meLoading
  ) {
    return <LoadingPage />;
  }

  if (
    consultationsQueryError ||
    getMedicalOfficesPractitionersError ||
    meError ||
    !meData?.me ||
    !consultationsQueryData?.consultations ||
    !getMedicalOfficesPractitionersData?.medicalOffices ||
    !getMedicalOfficesPractitionersData?.practitioners
  ) {
    return <ErrorPage />;
  }

  return (
    <>
      {consultationsListMode && (
        <ConsultationsList
          consultations={consultationsQueryData.consultations.items}
          totalConsultations={consultationsQueryData.consultations.totalItemsCount}
          fetchMore={fetchMoreConsultationsHandler}
          handleGoToCreateConsultationMode={handleGoToCreateConsultationMode}
          handleGoToEditConsultationMode={handleGoToEditConsultationMode}
        />
      )}
      {createConsultationMode && (
        <CreateConsultationForm
          patientUuid={patientUuid}
          handleBackButton={handleGoToConsultationsListMode}
          paymentMethods={paymentMethods}
          medicalOffices={medicalOffices}
          practitioners={practitioners}
          getMedicalOfficesPractitionersData={getMedicalOfficesPractitionersData}
          createConsultationMutation={createConsultationMutation}
        />
      )}
      {editConsultationMode && (
        <EditConsultationForm
          loadingMutation={modifyConsultationLoading}
          handleBackButton={handleGoToConsultationsListMode}
          editConsultationMode={editConsultationMode}
          paymentMethods={paymentMethods}
          medicalOffices={medicalOffices}
          practitioners={practitioners}
          modifyConsultationMutation={modifyConsultationMutation}
          createInvoiceMutation={createInvoiceMutation}
          sendInvoiceByMailMutation={sendInvoiceByMailMutation}
          sendInvoiceByMailLoading={sendInvoiceByMailLoading}
          loadingCreateInvoiceMutation={createInvoiceLoading}
          getMedicalOfficesPractitionersData={getMedicalOfficesPractitionersData}
        />
      )}
    </>
  );
}

export default ConsultationPanelContainer;
