import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import CreatePatientTemplate from '../../components/templates/createPatient';
import { CreatePatientFormValues } from './types';
import createPatientSchema from '../../schemas/createPatient';
import { notifyToasterError, notifyToasterSuccess } from '../../utils/toaster';
import CREATE_PATIENT_MUTATION from '../../graphql/createPatient';
import { Gender, PatientInput } from '../../types/codegen/graphql';
import LoadingPage from '../loading';
import ErrorPage from '../error';
import { ROUTE_PATIENT } from '../../routes';

const genders = ['Femme', 'Homme', 'Autre'];
const gendersMap: Record<string, Gender> = {
  Femme: Gender.Female,
  Homme: Gender.Male,
  Autre: Gender.Other,
};

function CreatePatient() {
  /**
   * Routers
   */

  const history = useHistory();

  /**
   * States
   */
  const [selectedGender, setSelectedGender] = useState(genders[0]);

  /**
   * Apollo
   */
  const [
    createPatientMutation,
    { loading: createPatientMutationLoading, error: createPatientMutationError },
  ] = useMutation(CREATE_PATIENT_MUTATION);

  /**
   * Form
   */

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<CreatePatientFormValues>({
    resolver: yupResolver(createPatientSchema),
  });

  /**
   * Handler
   */

  const onSubmit: SubmitHandler<CreatePatientFormValues> = async (
    values: CreatePatientFormValues,
  ) => {
    try {
      const patientInput: PatientInput = {
        firstname: values.firstname,
        lastname: values.lastname,
        gender: gendersMap[values.gender],
        email: values.email || null,
        phoneNumber: values.phoneNumber || null,
        birthdate: values.birthdate || null,
        job: values.job || null,
        hobbies: values.hobbies || null,
        address: values.address || null,
        zipCode: values.zipCode || null,
        city: values.city || null,
        remark: values.remark || null,
        caseHistory: {
          family: values.family || null,
          medical: values.medical || null,
          traumatic: values.traumatic || null,
        },
        checkUp: {
          digestive: values.digestive || null,
          gynecology: values.gynecology || null,
          skull: values.skull || null,
          thoracic: values.thoracic || null,
        },
      };

      const result = await createPatientMutation({
        variables: {
          patientInput,
        },
      });

      if (!result.data?.createPatient?.uuid) {
        notifyToasterError("Une erreur s'est produite lors de la requête");
        return;
      }

      notifyToasterSuccess(`Patient ${values.lastname} ${values.firstname} crée avec succès !`);
      reset();
      history.push(`${ROUTE_PATIENT}/${result.data.createPatient.uuid}`);
    } catch (err) {
      notifyToasterError("Une erreur s'est produite");
    }
  };

  if (createPatientMutationLoading) {
    return <LoadingPage />;
  }

  if (createPatientMutationError) {
    return <ErrorPage />;
  }

  return (
    <>
      <Prompt when={isDirty} message="Es-tu sûr(e) de vouloir quitter cette page ?" />
      <CreatePatientTemplate
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        control={control}
        genders={genders}
        setSelectedGender={setSelectedGender}
        selectedGender={selectedGender}
      />
    </>
  );
}

export default CreatePatient;
