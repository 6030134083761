import { useMemo } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import formatDate from '../../../utils/formatDate';
import { NumberConsultationsPerMonthOverYear } from '../../../types/codegen/graphql';

interface Props {
  consultationsCount: number;
  lastConsultationDate: string | null;
  numberConsultationsPerMonthCurrentYear: NumberConsultationsPerMonthOverYear[];
  numberConsultationsPerMonthLastYear: NumberConsultationsPerMonthOverYear[];
}

function PatientStatisticsTab({
  consultationsCount,
  lastConsultationDate,
  numberConsultationsPerMonthCurrentYear,
  numberConsultationsPerMonthLastYear,
}: Props) {
  const numberConsultationsPerMonthCurrentYearGraphData = useMemo(() => {
    return numberConsultationsPerMonthCurrentYear.map((x) => ({
      date: `${x.month}/${x.year}`,
      consultationsCount: x.consultationsCount,
    }));
  }, [numberConsultationsPerMonthCurrentYear]);

  const numberConsultationsPerMonthLastYearGraphData = useMemo(() => {
    return numberConsultationsPerMonthLastYear.map((x) => ({
      date: `${x.month}/${x.year}`,
      consultationsCount: x.consultationsCount,
    }));
  }, [numberConsultationsPerMonthLastYear]);

  return (
    <div className="shadow-lg rounded-md px-4 py-5 bg-white sm:p-6">
      <div>
        <span className="font-bold mr-2">Nombre de consultations :</span>
        <span>{consultationsCount}</span>
      </div>
      <div>
        <span className="font-bold mr-2">Date de la dernière consultation :</span>
        {lastConsultationDate && <span>{formatDate(new Date(lastConsultationDate))}</span>}
      </div>
      <p className="text-center font-bold my-4">
        Nombre de consultations par mois pendant cette année
      </p>
      <div className="my-6">
        <ResponsiveContainer width="100%" height="100%" aspect={3}>
          <LineChart
            width={500}
            height={400}
            data={numberConsultationsPerMonthCurrentYearGraphData}
            margin={{
              top: 15,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid horizontal stroke="#243240" />
            <XAxis
              dataKey="date"
              tick={{ fill: '#000000' }}
              label={{ value: 'Mois', position: 'bottom' }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#0077b3',
                color: '#000000',
                borderRadius: '5px',
              }}
              itemStyle={{ color: '#000000' }}
              cursor={false}
            />
            <Line
              type="monotone"
              dataKey="consultationsCount"
              stroke="#009EED"
              strokeWidth="2"
              dot={{ fill: '#ffffff', stroke: '#0088a7', strokeWidth: 2, r: 5 }}
              activeDot={{ fill: '#ffffff', stroke: '#0088a7', strokeWidth: 5, r: 10 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <p className="text-center font-bold my-4">
        Nombre de consultations par mois l&apos;année dernière
      </p>
      <div className="my-6">
        <ResponsiveContainer width="100%" height="100%" aspect={3}>
          <LineChart
            width={500}
            height={400}
            data={numberConsultationsPerMonthLastYearGraphData}
            margin={{
              top: 15,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid horizontal stroke="#243240" />
            <XAxis
              dataKey="date"
              tick={{ fill: '#000000' }}
              label={{ value: 'Mois', position: 'bottom' }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#0077b3',
                color: '#000000',
                borderRadius: '5px',
              }}
              itemStyle={{ color: '#000000' }}
              cursor={false}
            />
            <Line
              type="monotone"
              dataKey="consultationsCount"
              stroke="#009EED"
              strokeWidth="2"
              dot={{ fill: '#ffffff', stroke: '#0088a7', strokeWidth: 2, r: 5 }}
              activeDot={{ fill: '#ffffff', stroke: '#0088a7', strokeWidth: 5, r: 10 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default PatientStatisticsTab;
