import { graphql } from '../types/codegen';

export default graphql(`
  mutation CreatePatient($patientInput: PatientInput!) {
    createPatient(patientInput: $patientInput) {
      uuid
      createdAt
      firstname
      lastname
      firstnameWithoutDiacritics
      lastnameWithoutDiacritics
      gender
      email
      phoneNumber
      birthdate
      job
      hobbies
      address
      zipCode
      city
      remark
      caseHistory {
        medical
        family
        traumatic
      }
      checkUp {
        thoracic
        skull
        gynecology
        digestive
      }
      lastConsultationDate
    }
  }
`);
