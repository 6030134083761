/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Cookies from 'js-cookie';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { SearchCircleIcon, TableIcon, UserAddIcon } from '@heroicons/react/outline';
import decode, { JwtPayload } from 'jwt-decode';
import { LogoutIcon } from '@heroicons/react/solid';
import { useQuery } from '@apollo/client';
import {
  ROUTE_CREATE_PATIENT,
  ROUTE_HOMEPAGE,
  ROUTE_LOGOUT,
  ROUTE_STATISTIQUES,
} from '../../routes';
import Layout from '../HOC/Layout';
import { NavigationMap } from '../../types';
import ME_QUERY from '../../graphql/me';
import LoadingPage from '../../pages/loading';

export const userIsAuthenticated = (): boolean => {
  const accessToken = Cookies.get(process.env.REACT_APP_AUTH_COOKIE as string);

  if (!accessToken) {
    return false;
  }

  try {
    const { exp } = decode<JwtPayload>(accessToken);
    const currentTime = new Date().getTime() / 1000;

    if (!exp || currentTime > exp) {
      return false;
    }

    return true;
  } catch (err) {
    return false;
  }
};

function PrivateRoute({ component: Component, ...rest }: any) {
  const { data: meData, loading: meLoading, error: meError } = useQuery(ME_QUERY);

  const location = useLocation();

  // If the user is not authenticated, redirect him to LOGOUT route
  if (!userIsAuthenticated()) {
    return <Route {...rest} render={() => <Redirect to={ROUTE_LOGOUT} />} />;
  }

  if (meLoading) {
    return <LoadingPage />;
  }

  if (meError) {
    return <p>PrivateRoute: Error during me.query</p>;
  }

  const upperNavigation: NavigationMap[] = [
    {
      name: 'Homepage',
      url: ROUTE_HOMEPAGE,
      icon: <SearchCircleIcon className="h-6 w-6" />,
    },
    {
      name: 'Créer un patient',
      url: ROUTE_CREATE_PATIENT,
      icon: <UserAddIcon className="h-6 w-6" />,
    },
    ...(meData?.me.userRole === 'admin'
      ? [
          {
            name: 'Statistiques',
            url: ROUTE_STATISTIQUES,
            icon: <TableIcon className="h-66 w-6" />,
          },
        ]
      : []),
  ];

  const bottomNavigation: NavigationMap[] = [
    { name: 'Se déconnecter', url: ROUTE_LOGOUT, icon: <LogoutIcon className="h-6 w-6" /> },
  ];

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout
          upperNavigation={upperNavigation}
          bottomNavigation={bottomNavigation}
          currentUrl={location.pathname}
        >
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default PrivateRoute;
