import { graphql } from '../types/codegen';

export default graphql(`
  query consultationsCountWeekly {
    consultationsCountWeekly {
      count
      practitionerUuid
      firstname
      lastname
    }
  }
`);
