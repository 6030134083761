import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import { Control, Controller } from 'react-hook-form';

interface Props {
  values: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  label?: string;
  cornerHint?: string;
  helpText?: string;
  defaultValue?: string;
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  valuesClassNames?: string;
}

// TODO: Futur improvements: Consider a custom error mode for this atoms that works perfectly with react-hook-form
function Select({
  label,
  control,
  selected,
  setSelected,
  Icon,
  values,
  name,
  helpText,
  cornerHint,
  defaultValue,
  valuesClassNames,
}: Props) {
  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field: { onChange } }) => (
          <Listbox
            as="div"
            value={selected}
            onChange={(newValue) => {
              setSelected(newValue);
              onChange(newValue);
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="flex justify-between">
                  <div className="block text-sm font-medium text-gray-700">{label}</div>
                  {cornerHint && <span className="text-sm text-gray-500">{cornerHint}</span>}
                </Listbox.Label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <Listbox.Button
                    className={classnames(
                      'relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm',
                      { 'pl-10': Icon !== undefined },
                    )}
                  >
                    {Icon && (
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    )}
                    <span className={classnames('block truncate', valuesClassNames)}>
                      {selected}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {values.map((value) => (
                        <Listbox.Option
                          key={value}
                          className={({ active }) =>
                            classnames(
                              active ? 'text-white bg-primary' : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-8 pr-4',
                              valuesClassNames,
                            )
                          }
                          value={value}
                        >
                          {({ selected: isSelected, active }) => (
                            <>
                              <span
                                className={classnames(
                                  isSelected ? 'font-semibold' : 'font-normal',
                                  'block truncate',
                                  valuesClassNames,
                                )}
                              >
                                {value}
                              </span>

                              {isSelected ? (
                                <span
                                  className={classnames(
                                    active ? 'text-white' : 'text-primary',
                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        )}
      />
      {helpText && <p className="mt-2 text-sm text-gray-500">{helpText}</p>}
    </>
  );
}

Select.defaultProps = {
  label: '',
  cornerHint: '',
  helpText: '',
  defaultValue: '',
  valuesClassNames: '',
  Icon: undefined,
};

export default Select;
