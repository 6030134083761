/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import { DeepMap, FieldErrors, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import { REQUIRED_FIELD } from '../../../utils/constants';

interface Props {
  domId: string;
  register: UseFormRegisterReturn;
  errors: FieldErrors;
  label?: string;
  placeholder?: string;
  rows?: number;
}

function Textarea({ rows, domId, register, errors, label, placeholder }: Props) {
  const error: DeepMap<FieldValues, FieldErrors> | null = errors[register.name] || null;

  return (
    <div>
      <label htmlFor={domId} className="block text-sm font-medium font-roboto text-gray-700 mb-2">
        {label}
      </label>
      <textarea
        className={classnames(
          {
            'shadow-sm focus:ring-primary focus:border-primary block w-full placeholder-gray-400 sm:text-sm border-gray-300 rounded-md':
              error === null,
          },
          {
            'block w-full border-red-300 text-red-900 placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md':
              error !== null,
          },
        )}
        rows={rows}
        placeholder={placeholder}
        id={domId}
        {...register}
      />
      {error && error.message !== REQUIRED_FIELD && (
        <p className="mt-2 text-sm text-red-600" id={`${domId}-error`}>
          {error.message}
        </p>
      )}
    </div>
  );
}

Textarea.defaultProps = {
  label: '',
  placeholder: '',
  rows: 4,
};

export default Textarea;
