/* eslint-disable react/no-children-prop */
import React from 'react';

import { Switch } from '@headlessui/react';
import classnames from 'classnames';
import { Size, VariantColor } from '../../../types';

interface Props {
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  classNames?: string;
  withIcon?: boolean;
  color?: VariantColor;
  size?: Size;
  children?: React.ReactNode;
}

function Icon({ enabled, color }: Pick<Props, 'enabled' | 'color'>) {
  return (
    <>
      <span
        className={classnames(
          enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
        )}
        aria-hidden="true"
      >
        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path
            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <span
        className={classnames(
          enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
          'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
        )}
        aria-hidden="true"
      >
        <svg className={`h-3 w-3 text-${color}`} fill="currentColor" viewBox="0 0 12 12">
          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
        </svg>
      </span>
    </>
  );
}

function ShortToggle({
  enabled,
  setEnabled,
  color,
  withIcon,
  classNames,
  children,
}: Omit<Props, 'size'>) {
  return (
    <Switch.Group as="div" className={`flex items-center ${classNames}`}>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className="pointer-events-none absolute bg-white w-full h-full rounded-md"
        />
        <span
          aria-hidden="true"
          className={classnames(
            enabled ? `bg-${color}` : 'bg-gray-200',
            'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
          )}
        />
        <span
          aria-hidden="true"
          className={classnames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
          )}
        >
          {withIcon && <Icon enabled={enabled} color={color} />}
        </span>
      </Switch>
      {children && (
        <Switch.Label as="span" className="ml-3">
          {children}
        </Switch.Label>
      )}
    </Switch.Group>
  );
}

function BigToggle({
  enabled,
  setEnabled,
  color,
  withIcon,
  classNames,
  children,
}: Omit<Props, 'size'>) {
  return (
    <Switch.Group as="div" className={`flex items-center ${classNames}`}>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classnames(
          enabled ? `bg-${color}` : 'bg-gray-200',
          `relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}`,
        )}
      >
        <span
          aria-hidden="true"
          className={classnames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        >
          {withIcon && <Icon enabled={enabled} color={color} />}
        </span>
      </Switch>
      {children && (
        <Switch.Label as="span" className="ml-3">
          {children}
        </Switch.Label>
      )}
    </Switch.Group>
  );
}

function Toggle({ enabled, setEnabled, color, size, withIcon, children, classNames }: Props) {
  return [Size.M, Size.L].includes(size as Size) ? (
    <BigToggle
      enabled={enabled}
      setEnabled={setEnabled}
      color={color}
      withIcon={withIcon}
      children={children}
      classNames={classNames}
    />
  ) : (
    <ShortToggle
      enabled={enabled}
      setEnabled={setEnabled}
      color={color}
      withIcon={withIcon}
      children={children}
      classNames={classNames}
    />
  );
}

Toggle.defaultProps = {
  color: VariantColor.PRIMARY,
  size: Size.M,
  withIcon: true,
  children: '',
  classNames: '',
};

export default Toggle;
