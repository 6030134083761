export enum Size {
  S = 's',
  M = 'm',
  L = 'l',
}

// tailwind.config.js to see the colors values.
export enum VariantColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DISABLED = 'disabled',
  INFO = 'info',
  WHITE = 'white',
}
