import { useEffect, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { ArrowLeftIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
import {
  CashIcon,
  DownloadIcon,
  MailIcon,
  PencilAltIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MutationTuple, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CONSULTATION_QUERY from '../../../graphql/consultation';
import DOWNLOAD_INVOICE_QUERY from '../../../graphql/downloadInvoice';
import PATIENT_QUERY from '../../../graphql/patient';
import PATIENT_NUMBER_CONSULTATION_PER_MONTHS_STATS from '../../../graphql/patientNumberConsultationLastCurrentYearStats';
import PAGINATED_CONSULTATIONS_QUERY from '../../../graphql/paginatedConsultations';
import {
  CreateInvoiceFormValues,
  ModifyConsultationFormValues,
  SendInvoiceFormValues,
} from '../../../pages/patient/types';
import {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  GetMedicalOfficePractitionersQuery,
  ModifyConsultationInput,
  ModifyConsultationMutation,
  ModifyConsultationMutationVariables,
  PaymentMethod,
  SendInvoiceByMailMutation,
  SendInvoiceByMailMutationVariables,
  SortOrder,
} from '../../../types/codegen/graphql';
import Button from '../../atoms/Button';
import modifyConsultationSchema from '../../../schemas/modifyConsultation';
import Textarea from '../../atoms/Textarea';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import stripObject from '../../../utils/stripObject';
import deleteObjectAttributesByKeys from '../../../utils/deleteObjectAttributesByKeys';
import {
  notifyToasterError,
  notifyToasterInfo,
  notifyToasterSuccess,
} from '../../../utils/toaster';
import DELETE_INVOICE_MUTATION from '../../../graphql/deleteInvoice';
import LoadingPage from '../../../pages/loading';
import ErrorPage from '../../../pages/error';
import { Size, VariantColor } from '../../../types';
import formatDate from '../../../utils/formatDate';
import downloadPDFFile from '../../../utils/downloadPdfFile';
import EditInvoiceForm from '../EditInvoiceForm';
import CreateInvoiceForm from '../CreateInvoiceForm';
import SendInvoiceForm from '../SendInvoiceForm';
import Modal from '../Modal';

interface Props {
  handleBackButton: () => void;
  editConsultationMode: string; // consultation uuid
  medicalOffices: string[];
  practitioners: string[];
  paymentMethods: string[];
  loadingMutation: boolean;
  loadingCreateInvoiceMutation: boolean;
  getMedicalOfficesPractitionersData: GetMedicalOfficePractitionersQuery;
  modifyConsultationMutation: MutationTuple<
    ModifyConsultationMutation,
    ModifyConsultationMutationVariables
  >['0'];
  createInvoiceMutation: MutationTuple<CreateInvoiceMutation, CreateInvoiceMutationVariables>['0'];
  sendInvoiceByMailMutation: MutationTuple<
    SendInvoiceByMailMutation,
    SendInvoiceByMailMutationVariables
  >['0'];
  sendInvoiceByMailLoading: boolean;
}

function EditConsultationForm({
  handleBackButton,
  editConsultationMode,
  loadingMutation,
  practitioners,
  medicalOffices,
  modifyConsultationMutation,
  createInvoiceMutation,
  sendInvoiceByMailMutation,
  sendInvoiceByMailLoading,
  loadingCreateInvoiceMutation,
  getMedicalOfficesPractitionersData,
  paymentMethods,
}: Props) {
  /**
   * Params
   */
  const { uuid: patientUuid } = useParams<{ uuid: string }>();

  const [deleteInvoiceMutation, { loading: deleteInvoiceMutationLoading }] =
    useMutation(DELETE_INVOICE_MUTATION);

  /**
   * States
   */

  const [selectedMedicalOffice, setSelectedMedicalOffice] = useState<string>('');
  const [selectedPractitioner, setSelectedPractitioner] = useState<string>('');
  const [showQuitWarning, setShowQuitWarning] = useState<boolean>(false);
  const [editInvoiceMode, setEditInvoiceMode] = useState<boolean>(false);
  const [createInvoiceMode, setCreateInvoiceMode] = useState<boolean>(false);
  const [sendInvoiceMode, setSendInvoiceMode] = useState<boolean>(false);
  const [deleteInvoiceShowModal, setDeleteInvoiceShowModal] = useState<boolean>(false);

  /**
   * Side-Effects
   */

  // Switch Mode
  useEffect(() => {
    if (sendInvoiceMode) {
      setEditInvoiceMode(false);
    } else if (editInvoiceMode) {
      setSendInvoiceMode(false);
    }
  }, [sendInvoiceMode, editInvoiceMode]);

  /**
   * Form
   */

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    reset: resetForm,
    control,
    setValue: setFormValue,
  } = useForm<ModifyConsultationFormValues>({
    resolver: yupResolver(modifyConsultationSchema),
  });

  /**
   * Apollo
   */

  const [downloadInvoiceQuery] = useLazyQuery(DOWNLOAD_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: consultationQueryData,
    loading: consultationQueryLoading,
    error: consultationQueryError,
  } = useQuery(CONSULTATION_QUERY, {
    variables: { consultationUuid: editConsultationMode },
    onCompleted: ({ consultation }) => {
      if (consultation) {
        setSelectedMedicalOffice(consultation.medicalOffice.name);
        setSelectedPractitioner(consultation.practitioner.lastnameFirstname);
        setFormValue('advice', consultation.advice ?? '');
        setFormValue('cause', consultation.cause ?? '');
        setFormValue('evolution', consultation.evolution ?? '');
        setFormValue('medicalOffice', consultation.medicalOffice?.name ?? '');
        setFormValue('practitioner', consultation.practitioner?.lastnameFirstname ?? '');
        setFormValue('remark', consultation.remark ?? '');
        setFormValue('treatment', consultation.treatment ?? '');
        setFormValue(
          'date',
          consultation.date ? new Date(consultation.date).toISOString().split('T')[0] : '',
        );
      }
    },
  });

  const {
    data: patientQueryData,
    loading: patientQueryLoading,
    error: patientQueryError,
  } = useQuery(PATIENT_QUERY, {
    variables: { patientUuid },
  });

  /**
   * Handler
   */

  const handleDownloadInvoice = async (
    invoiceUuid: string,
    firstname: string,
    lastname: string,
    date: string,
  ) => {
    try {
      notifyToasterInfo('Téléchargement de la facture en cours ...');
      const requestResult = await downloadInvoiceQuery({
        variables: { invoiceUuid },
        fetchPolicy: 'no-cache',
      });

      if (!requestResult?.data?.downloadInvoice) {
        throw new Error('front: error during download invoice');
      }

      const base64PdfFile = requestResult.data.downloadInvoice;
      const filename = `${firstname} ${lastname} Facture du ${formatDate(new Date(date))}`
        .replace(/[^a-z0-9]/gi, '_')
        .toLowerCase();

      downloadPDFFile(base64PdfFile, filename);
    } catch (err) {
      notifyToasterError(
        `Erreur lors du téléchargement de la facture ${invoiceUuid}. Veuillez ne pas réessayer et contacter l'administrateur de l'application`,
      );
    }
  };

  const handleDeleteInvoice = async () => {
    try {
      const invoiceUuid = consultationQueryData?.consultation?.associatedInvoice?.uuid;

      if (invoiceUuid && !deleteInvoiceMutationLoading) {
        const res = await deleteInvoiceMutation({
          variables: { invoiceUuid },
          refetchQueries: [
            {
              query: CONSULTATION_QUERY,
              variables: {
                consultationUuid: consultationQueryData.consultation?.uuid,
              },
            },
            { query: PATIENT_QUERY, variables: { patientUuid } },
          ],
        });
        notifyToasterSuccess(res.data?.deleteInvoice || '');
      }
    } catch (err) {
      notifyToasterError('Une erreur est survenue');
    } finally {
      setDeleteInvoiceShowModal(false);
    }
  };

  const onSubmit: SubmitHandler<ModifyConsultationFormValues> = async ({
    practitioner,
    medicalOffice,
    ...formValues
  }) => {
    try {
      const dirtyKeys = Object.keys(dirtyFields);
      const practitionerUuid = getMedicalOfficesPractitionersData.practitioners.find(
        (x) => x.lastnameFirstname === practitioner,
      )?.uuid;

      const medicalOfficeUuid = getMedicalOfficesPractitionersData.medicalOffices.find(
        (x) => x.name === medicalOffice,
      )?.uuid;

      const sanitizedFormValues: ModifyConsultationInput = deleteObjectAttributesByKeys(
        stripObject(formValues),
        dirtyKeys,
      );

      const modifyConsultationInput = {
        ...(medicalOfficeUuid && dirtyKeys.includes('medicalOffice') ? { medicalOfficeUuid } : {}),
        ...(practitionerUuid && dirtyKeys.includes('practitioner') ? { practitionerUuid } : {}),
        ...sanitizedFormValues,
      };

      if (Object.keys(modifyConsultationInput).length === 0) {
        notifyToasterInfo("Aucune modification enregistrée car aucun champ n'a été modifié", 10000);
        return;
      }

      await modifyConsultationMutation({
        variables: {
          consultationUuid: editConsultationMode,
          modifyConsultationInput,
        },
        refetchQueries: [
          {
            query: CONSULTATION_QUERY,
            variables: {
              consultationUuid: editConsultationMode,
            },
          },
          {
            query: PAGINATED_CONSULTATIONS_QUERY,
            variables: {
              filters: { patientUuids: [patientUuid] },
              sort: { date: SortOrder.Desc },
              skip: 0,
              take: 10,
            },
          },
          { query: PATIENT_QUERY, variables: { patientUuid } },
          {
            query: PATIENT_NUMBER_CONSULTATION_PER_MONTHS_STATS,
            variables: {
              patientUuid,
              currentYear: new Date().getFullYear(),
              lastYear: new Date().getFullYear() - 1,
            },
          },
        ],
      });

      notifyToasterSuccess(`Consultation modifiée avec succès`);
      resetForm();
      handleBackButton();
    } catch (err) {
      notifyToasterError(
        "Erreur lors de la modification de la consultation. Veuillez contacter l'administrateur",
      );
    }
  };

  const onSubmitCreateInvoice: SubmitHandler<CreateInvoiceFormValues> = async (formValues) => {
    try {
      if (!consultationQueryData?.consultation) {
        return;
      }

      await createInvoiceMutation({
        variables: {
          createInvoiceInput: {
            date: consultationQueryData.consultation.date,
            firstname: formValues.firstname,
            lastname: formValues.lastname,
            medicalOfficeUuid: consultationQueryData.consultation.medicalOfficeUuid,
            patientUuid: consultationQueryData.consultation.patientUuid,
            paymentMethod: formValues.paymentMethod as PaymentMethod,
            price: formValues.price,
            consultationUuidToLink: consultationQueryData.consultation.uuid,
          },
        },
        refetchQueries: [
          {
            query: CONSULTATION_QUERY,
            variables: {
              consultationUuid: editConsultationMode,
            },
          },
          { query: PATIENT_QUERY, variables: { patientUuid } },
        ],
      });

      notifyToasterSuccess('Facture créée avec succès');
    } catch (err) {
      notifyToasterError(
        "Erreur lors de la création de la facture. Veuillez contacter l'administrateur",
      );
    }
  };

  const onSubmitSendInvoice: SubmitHandler<SendInvoiceFormValues> = async ({ customMessage }) => {
    try {
      const invoiceUuid = consultationQueryData?.consultation?.associatedInvoice?.uuid;

      if (!invoiceUuid) {
        return;
      }

      await sendInvoiceByMailMutation({
        variables: { invoiceUuid, ...(customMessage ? { customMessage } : {}) },
      });

      notifyToasterSuccess('Facture envoyée avec succès');
    } catch (err) {
      notifyToasterError(
        "Erreur lors de l'envoi de la facture par mail. Veuillez contacter l'administrateur",
      );
    } finally {
      setSendInvoiceMode(false);
    }
  };

  /**
   * Render
   */

  if (consultationQueryLoading || patientQueryLoading) {
    return <LoadingPage />;
  }

  if (
    consultationQueryError ||
    patientQueryError ||
    !patientQueryData?.patient ||
    !consultationQueryData?.consultation
  ) {
    return <ErrorPage />;
  }

  return (
    <>
      <Prompt
        when={isDirty}
        message="Une modification d'une consultation est en cours. Es-tu sûr de vouloir quitter cette page ? "
      />
      <Modal show={deleteInvoiceShowModal}>
        <p className="text-red-500 font-bold my-4 text-lg">
          Êtes-vous sûr de vouloir supprimer cette facture ?
        </p>
        <p className="text-red-500 font-bold">
          Elle ne sera donc plus comptabilisé dans la comptabilité/calculs de l&apos;application et
          supprimée définitivement de la base de données.
        </p>
        <div className="flex justify-center my-4">
          <Button
            classNames="mr-10 text-red-500 font-bold"
            color={VariantColor.WHITE}
            size={Size.L}
            onClick={handleDeleteInvoice}
          >
            Je confirme
          </Button>
          <Button size={Size.L} onClick={() => setDeleteInvoiceShowModal(false)}>
            Annuler
          </Button>
        </div>
      </Modal>
      <div className="mb-2 mx-auto text-center">
        {!showQuitWarning && (
          <Button
            Icon={ArrowLeftIcon}
            onClick={!isDirty ? handleBackButton : () => setShowQuitWarning(true)}
          >
            Retour
          </Button>
        )}
        {isDirty && showQuitWarning && (
          <Button Icon={ArrowLeftIcon} onClick={handleBackButton} color={VariantColor.SECONDARY}>
            Je veux quitter cette page
          </Button>
        )}
        {showQuitWarning && <p className="font-bold mt-2">Veux-tu vraiment quitter cette page ?</p>}
      </div>
      <div className="bg-white rounded-md shadow-lg">
        <form onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex flex-row my-4">
              <PencilAltIcon className="h-6 w-6 text-primary mx-1" />
              <span className="text-primary font-bold text-xl">Modifier une consultation</span>
            </div>
            <div className="grid grid-cols-4 gap-6 my-4">
              <div className="col-span-full">
                <Input
                  domId="date"
                  autoComplete="date"
                  inputType="date"
                  register={register('date')}
                  errors={errors}
                  label="Date"
                  placeholder="JJ/MM/AAAA"
                  max={new Date().toISOString().split('T')[0]}
                  min="1903-01-02"
                  helpText="⚠️ Si il existe une facture associée à cette consultation, la date de la facture sera modifiée également."
                />
              </div>
              <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                <Select
                  label="Praticien"
                  control={control}
                  name="practitioner"
                  selected={selectedPractitioner}
                  setSelected={setSelectedPractitioner}
                  values={practitioners}
                />
              </div>
              <div className="col-span-2 sm:col-span-2 lg:col-span-2">
                <Select
                  label="Cabinet"
                  control={control}
                  name="medicalOffice"
                  selected={selectedMedicalOffice}
                  setSelected={setSelectedMedicalOffice}
                  values={medicalOffices}
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="cause"
                  register={register('cause')}
                  errors={errors}
                  label="Motif de consultation"
                  placeholder="Motif de consultation"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="remark"
                  register={register('remark')}
                  errors={errors}
                  label="Remarques"
                  placeholder="Remarques"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="treatment"
                  register={register('treatment')}
                  errors={errors}
                  label="Traitements"
                  placeholder="Traitements"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="advice"
                  register={register('advice')}
                  errors={errors}
                  label="Conseils"
                  placeholder="Conseils"
                />
              </div>
              <div className="col-span-full my-2">
                <Textarea
                  domId="evolution"
                  register={register('evolution')}
                  errors={errors}
                  label="Évolutions"
                  placeholder="Evolutions"
                />
              </div>
              <div className="col-span-full my-4 flex flex-row justify-center">
                <Button
                  classNames="font-pacifico"
                  Icon={PencilAltIcon}
                  disabled={!isDirty || loadingMutation}
                  submit
                >
                  Modifier la consultation
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div className="col-span-full my-4 p-4">
          <div className="flex flex-row">
            <CashIcon className="h-6 w-6 text-primary mx-1" />
            <span className="text-primary font-bold text-xl">Facture associée</span>
          </div>
          {/* Mode : Une facture existe pour cette consultation et nous ne sommes PAS dans le mode d'édition NI d'envoi */}
          {consultationQueryData.consultation?.associatedInvoice &&
            !editInvoiceMode &&
            !sendInvoiceMode && (
              <div className="px-6 py-4">
                <Button
                  classNames="mx-2"
                  Icon={DownloadIcon}
                  size={Size.S}
                  onClick={() =>
                    handleDownloadInvoice(
                      consultationQueryData.consultation!.associatedInvoice!.uuid,
                      consultationQueryData.consultation!.associatedInvoice!.firstname,
                      consultationQueryData.consultation!.associatedInvoice!.lastname,
                      consultationQueryData.consultation!.associatedInvoice!.date,
                    )
                  }
                />
                <Button
                  classNames="mx-2"
                  color={VariantColor.SECONDARY}
                  Icon={PencilIcon}
                  size={Size.S}
                  onClick={() => setEditInvoiceMode(true)}
                />
                <Button
                  classNames="mx-2 text-primary font-bold"
                  color={VariantColor.WHITE}
                  Icon={MailIcon}
                  size={Size.S}
                  onClick={() => setSendInvoiceMode(true)}
                />
                <Button
                  classNames="mx-2 text-red-600 font-bold"
                  color={VariantColor.WHITE}
                  Icon={TrashIcon}
                  size={Size.S}
                  onClick={() => setDeleteInvoiceShowModal(true)}
                />
                <span className="font-roboto text-xl">Facture </span>
                <span>
                  {' '}
                  du{' '}
                  {formatDate(new Date(consultationQueryData.consultation.associatedInvoice.date))}
                  {' - '}
                </span>
                <span className="italic font-roboto">
                  {consultationQueryData.consultation.associatedInvoice.paymentMethod} -{' '}
                  {consultationQueryData.consultation.associatedInvoice.price}&#x20AC;
                </span>
              </div>
            )}
          {/* Mode : Une facture existe pour cette consultation et nous sommes dans le mode d'édition */}
          {consultationQueryData.consultation?.associatedInvoice && editInvoiceMode && (
            <EditInvoiceForm
              currentConsultationUuid={editConsultationMode}
              paymentMethods={paymentMethods}
              setEditInvoiceMode={setEditInvoiceMode}
              invoicePaymentMethod={
                consultationQueryData.consultation.associatedInvoice.paymentMethod
              }
              invoiceFirstname={consultationQueryData.consultation.associatedInvoice.firstname}
              invoiceLastname={consultationQueryData.consultation.associatedInvoice.lastname}
              invoicePrice={consultationQueryData.consultation.associatedInvoice.price}
              invoiceUuid={consultationQueryData.consultation.associatedInvoice.uuid}
            />
          )}
          {/* Mode : Une facture existe pour cette consultation et nous sommes dans le mode d'envoi */}
          {consultationQueryData.consultation?.associatedInvoice && sendInvoiceMode && (
            <SendInvoiceForm
              onSubmitSendInvoice={onSubmitSendInvoice}
              setSendInvoiceMode={setSendInvoiceMode}
              patientEmail={patientQueryData.patient.email}
              loading={sendInvoiceByMailLoading}
            />
          )}
          {/* Mode : Une facture n'existe PAS pour cette consultation et nous ne sommes PAS dans le mode de création */}
          {!consultationQueryData.consultation?.associatedInvoice && !createInvoiceMode && (
            <div className="flex flex-col justify-center">
              <div className="flex flex-row my-2">
                <p className="my-2 text-md font-bold">
                  ⚠ Aucune facture n&apos;est associée à cette consultation. Cette consultation
                  n&apos;est donc pas prise en compte dans la comptabilité de l&apos;application.
                  Vous pouvez tout de même générer une consultation rétro-activement avec le bouton
                  ci-dessous.
                </p>
              </div>
              <Button
                color={VariantColor.SECONDARY}
                Icon={PlusIcon}
                size={Size.S}
                onClick={() => setCreateInvoiceMode(true)}
              >
                Génerer une facture
              </Button>
            </div>
          )}
          {/* Mode : Une facture n'existe PAS pour cette consultation et nous sommes dans le mode de création */}
          {!consultationQueryData.consultation?.associatedInvoice && createInvoiceMode && (
            <CreateInvoiceForm
              loading={loadingCreateInvoiceMutation}
              setCreateInvoiceMode={setCreateInvoiceMode}
              onSubmit={onSubmitCreateInvoice}
              patientFirstname={patientQueryData.patient.firstname}
              patientLastname={patientQueryData.patient.lastname}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default EditConsultationForm;
