import * as yup from 'yup';
import { CreateInvoiceFormValues } from '../pages/patient/types';
import { BAD_FIRSTNAME, BAD_LASTNAME, BAD_PRICE } from '../utils/constants';

const schema: yup.SchemaOf<CreateInvoiceFormValues> = yup.object().shape({
  price: yup.number().typeError(BAD_PRICE).min(0, BAD_PRICE).max(2000, BAD_PRICE).required(),
  paymentMethod: yup.string().required(),
  firstname: yup.string().max(50, BAD_FIRSTNAME).required(),
  lastname: yup.string().max(50, BAD_LASTNAME).required(),
});

export default schema;
