import React from 'react';
import { useMediaQuery } from 'react-responsive';
import LoginForm from '../organisms/LoginForm';
import loginSvg from '../img/loginSvg.svg';

type Props = React.ComponentProps<typeof LoginForm>;

function LoginTemplate({ handleSubmit, onSubmit, register, loading, errors }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <span className="text-primary font-lilitaOne text-2xl">Mélanie Tordjman Ostéopathe</span>
          <p className="text-gray text-sm">Logiciel - Gestion cabinet</p>
          <div className="mt-8">
            <LoginForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              errors={errors}
              loading={loading}
            />
          </div>
          {!isMobile && <img src={loginSvg} alt="medicImage" className="mt-10 opacity-80" />}
          <footer className="h-10 text-center text-xs text-gray-400 mt-6">
            Made with &#10084; by Doudou
          </footer>
        </div>
      </div>
      <div className="hidden lg:block relative flex-1 backgroundLogin" />
    </div>
  );
}

export default LoginTemplate;
