import { useQuery } from '@apollo/client';
import ME_QUERY from '../../graphql/me';
import ErrorPage from '../error';
import LoadingPage from '../loading';
import ConsultationsCountWeeklyContainer from './containers/ConsultationsCountWeeklyContainer';

function StatistiquesPage() {
  /**
   * Apollo
   */

  const { data: meData, loading: meLoading, error: meError } = useQuery(ME_QUERY);

  if (meLoading) {
    return <LoadingPage />;
  }

  if (meError || !meData?.me) {
    return <ErrorPage />;
  }

  if (meData.me.userRole !== 'admin') {
    return <p>Accès non autorisé</p>;
  }

  return (
    <div className="p-8">
      <ConsultationsCountWeeklyContainer />
    </div>
  );
}

export default StatistiquesPage;
