import * as yup from 'yup';
import { ModifyPatientCaseHistoryCheckUpFormValues } from '../pages/patient/types';
import { BAD_LENGTH } from '../utils/constants';

const schema: yup.SchemaOf<ModifyPatientCaseHistoryCheckUpFormValues> = yup.object().shape({
  traumatic: yup.string().max(20000, BAD_LENGTH),
  medical: yup.string().max(20000, BAD_LENGTH),
  family: yup.string().max(20000, BAD_LENGTH),
  skull: yup.string().max(20000, BAD_LENGTH),
  thoracic: yup.string().max(20000, BAD_LENGTH),
  digestive: yup.string().max(20000, BAD_LENGTH),
  gynecology: yup.string().max(20000, BAD_LENGTH),
});

export default schema;
