/* eslint-disable no-plusplus */
import { InMemoryCache, ApolloClient, createHttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get(process.env.REACT_APP_AUTH_COOKIE as string);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (message.match(/Access denied! You need to be authorized to perform this action!/)) {
        // When a request return an unauthorized error (invalid auth token), the page reload to disconnect the user properly and redirect him to login page.
        window.location.reload();
      }
    });
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        consultations: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['filters', 'sort'],

          // Concatenate the incoming list items with
          // the existing list items.
          merge(incoming, existing) {
            if (!incoming) {
              return existing;
            }

            const merged = { ...existing, items: [...incoming.items, ...existing.items] };
            return merged;
          },
        },
      },
    },
    Patient: {
      keyFields: ['uuid'],
    },
  },
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API,
  cache,
  link: from([errorLink, authLink, httpLink]),
});

export default client;
