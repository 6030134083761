import * as yup from 'yup';
import { CreateConsultationFormValues } from '../pages/patient/types';
import {
  BAD_DATE,
  BAD_FIRSTNAME,
  BAD_LASTNAME,
  BAD_LENGTH,
  BAD_PRICE,
  birthdateFinalFormatRegex,
  birthdateInputDateRegex,
  REQUIRED_FIELD,
} from '../utils/constants';

const schema: yup.SchemaOf<CreateConsultationFormValues> = yup.object().shape({
  date: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('is-valid-date', BAD_DATE, (value) => {
      if (!value) {
        return false;
      }

      return birthdateInputDateRegex.test(value) || birthdateFinalFormatRegex.test(value);
    }),
  price: yup.number().typeError(BAD_PRICE).min(0, BAD_PRICE).max(2000, BAD_PRICE),
  paymentMethod: yup.string(),
  medicalOffice: yup.string().required(REQUIRED_FIELD),
  practitioner: yup.string().required(REQUIRED_FIELD),
  cause: yup.string().max(20000, BAD_LENGTH),
  remark: yup.string().max(20000, BAD_LENGTH),
  treatment: yup.string().max(20000, BAD_LENGTH),
  advice: yup.string().max(20000, BAD_LENGTH),
  evolution: yup.string().max(20000, BAD_LENGTH),
  customMessage: yup.string().max(900000, BAD_LENGTH),
  invoiceFirstname: yup.string().max(50, BAD_FIRSTNAME),
  invoiceLastname: yup.string().max(50, BAD_LASTNAME),
});

export default schema;
