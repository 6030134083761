import { ChevronDoubleDownIcon, EyeIcon, PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React from 'react';
import { Size } from '../../../types';
import { ConsultationsQuery } from '../../../types/codegen/graphql';
import formatDate from '../../../utils/formatDate';
import Button from '../../atoms/Button';

interface Props {
  consultations: ConsultationsQuery['consultations']['items'];
  totalConsultations: number;
  fetchMore: () => void;
  handleGoToCreateConsultationMode: () => void;
  handleGoToEditConsultationMode: (consultationUuid: string) => void;
}

function ConsultationsList({
  consultations,
  totalConsultations,
  fetchMore,
  handleGoToCreateConsultationMode,
  handleGoToEditConsultationMode,
}: Props) {
  return (
    <>
      <div className="flex justify-center my-2">
        <Button onClick={handleGoToCreateConsultationMode} Icon={PlusIcon}>
          Nouvelle consultation
        </Button>
      </div>
      <div className="rounded-md bg-white shadow overflow-y-scroll h-2/4">
        <ul className="divide-y divide-gray-200">
          {consultations.map(({ date, uuid, practitioner }, idx) => (
            <li key={uuid} className={classNames('px-6 py-4', { 'bg-gray-100': idx % 2 })}>
              <Button
                classNames="mx-2"
                Icon={EyeIcon}
                size={Size.S}
                onClick={() => handleGoToEditConsultationMode(uuid)}
              />
              <span className="font-roboto text-xl">Consultation </span>
              <span> du {formatDate(new Date(date))}</span>
              <span className="italic font-roboto">
                {' '}
                pris en charge par {practitioner.lastnameFirstname}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <p className="text-center my-2">
        {consultations.length}/{totalConsultations} consultations chargées
      </p>
      <div className="flex justify-center">
        <Button
          disabled={consultations.length === totalConsultations}
          onClick={fetchMore}
          Icon={ChevronDoubleDownIcon}
        >
          Charger les prochaines consultations
        </Button>
      </div>
    </>
  );
}

export default ConsultationsList;
