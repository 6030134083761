import { ApolloProvider } from '@apollo/client';
import { Switch, Route, Redirect } from 'react-router-dom';
import client from './client';
import PrivateRoute from './components/utils/PrivateRoute';
import PublicRoute from './components/utils/PublicRoute';
import NoMatchPage from './pages/404';
import AuthPage from './pages/auth';
import HomePage from './pages/home';
import PatientPage from './pages/patient';
import CreatePatient from './pages/createPatient';
import LogoutPage from './pages/logout';
import {
  ROUTE_CREATE_PATIENT,
  ROUTE_HOMEPAGE,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_PATIENT,
  ROUTE_STATISTIQUES,
} from './routes';
import StatistiquesPage from './pages/statistiques';

function App() {
  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route exact path="/">
          <Redirect to={ROUTE_LOGIN} />
        </Route>
        <PublicRoute restricted exact path={ROUTE_LOGIN} component={AuthPage} />
        <PrivateRoute restricted exact path={ROUTE_HOMEPAGE} component={HomePage} />
        <PrivateRoute restricted exact path={`${ROUTE_PATIENT}/:uuid`} component={PatientPage} />
        <PrivateRoute restricted exact path={`${ROUTE_CREATE_PATIENT}`} component={CreatePatient} />
        <PrivateRoute
          restricted
          exact
          path={`${ROUTE_STATISTIQUES}`}
          component={StatistiquesPage}
        />
        <PublicRoute restricted={false} exact path={ROUTE_LOGOUT} component={LogoutPage} />
        <PublicRoute restricted={false} path="*" component={NoMatchPage} />
      </Switch>
    </ApolloProvider>
  );
}

export default App;
