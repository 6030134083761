/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTE_HOMEPAGE } from '../../routes';
import { userIsAuthenticated } from './PrivateRoute';

function PublicRoute({ component: Component, restricted, ...rest }: any) {
  return (
    // restricted = false meaning fully public route (e.g. : 404 page, logout)
    // restricted = true meaning restricted public route, the user must not be authenticated (e.g. : login page)
    <Route
      {...rest}
      render={(props) =>
        userIsAuthenticated() && restricted ? (
          <Redirect to={ROUTE_HOMEPAGE} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default PublicRoute;
