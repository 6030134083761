import {
  BadgeCheckIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { toast } from 'react-toastify';

export const notifyToasterSuccess = (message: string, autoClose = 4000): void => {
  toast(message, {
    icon: () => <BadgeCheckIcon className="text-primary" />,
    bodyClassName: 'text-black font-roboto',
    progressClassName: 'Toastify__progress-bar--success',
    autoClose,
  });
};

export const notifyToasterInfo = (message: string, autoClose = 6000): void => {
  toast(message, {
    icon: () => <InformationCircleIcon className="text-secondary" />,
    bodyClassName: 'text-black font-roboto',
    progressClassName: 'Toastify__progress-bar--info',
    autoClose,
  });
};

export const notifyToasterError = (message: string, autoClose = 10000): void => {
  toast(message, {
    icon: () => <ExclamationCircleIcon className="text-red-400" />,
    bodyClassName: 'text-black font-roboto',
    progressClassName: 'Toastify__progress-bar--error',
    autoClose,
  });
};
