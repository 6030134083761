import { graphql } from '../types/codegen';

export default graphql(`
  query Patients(
    $skip: Float
    $take: Float
    $sort: PatientsQueryOrderByInput
    $filters: PatientsQueryFilters
  ) {
    patients(skip: $skip, take: $take, sort: $sort, filters: $filters) {
      hasMore
      totalItemsCount
      items {
        birthdate
        lastConsultationDate
        email
        phoneNumber
        firstname
        lastname
        uuid
      }
    }
  }
`);
