function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0');
}

// https://bobbyhadz.com/blog/javascript-format-date-dd-mm-yyyy
function formatDate(date: Date): string {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '/',
  );
}

export default formatDate;
