import { graphql } from '../types/codegen';

export default graphql(`
  query Consultation($consultationUuid: Uuid!) {
    consultation(consultationUuid: $consultationUuid) {
      uuid
      patientUuid
      practitionerUuid
      date
      createdAt
      medicalOfficeUuid
      invoiceUuid
      cause
      remark
      treatment
      advice
      evolution
      practitioner {
        lastnameFirstname
        uuid
      }
      medicalOffice {
        name
        uuid
      }
      associatedInvoice {
        date
        price
        paymentMethod
        firstname
        lastname
        uuid
      }
    }
  }
`);
