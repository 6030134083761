/* eslint-disable react/no-array-index-key */
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import React from 'react';
import { Size } from '../../../types';
import getPaginateRangesWithDot from '../../../utils/pagination';
import Button from '../../atoms/Button';

interface IProps {
  numberOfPages: number;
  currentPage: number;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  goToPage: (page: number) => void;
}

function Pagination({
  numberOfPages,
  currentPage,
  goToNextPage,
  goToPreviousPage,
  goToPage,
}: IProps) {
  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <Button size={Size.S} onClick={goToPreviousPage} Icon={ArrowLeftIcon}>
          Précédent
        </Button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {getPaginateRangesWithDot(currentPage, numberOfPages).map((str, idx) => {
          return (
            <span
              key={idx}
              onClick={() => (str !== '…' ? goToPage(parseInt(str, 10)) : undefined)}
              className={classnames(
                'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 ',
                { 'border-indigo-500 text-indigo-600': currentPage.toString() === str },
                { 'cursor-pointer hover:border-gray-500 hover:text-gray-700': str !== '…' },
              )}
            >
              {str}
            </span>
          );
        })}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <Button size={Size.S} onClick={goToNextPage} Icon={ArrowRightIcon}>
          Suivant
        </Button>
      </div>
    </nav>
  );
}

export default Pagination;
