import classnames from 'classnames';
import React from 'react';
import { UseFormRegisterReturn, FieldErrors, FieldValues, DeepMap } from 'react-hook-form';
import { REQUIRED_FIELD } from '../../../utils/constants';

interface Props {
  domId: string;
  register: UseFormRegisterReturn;
  errors: FieldErrors;
  inputType?: 'text' | 'number' | 'email' | 'password' | 'date';
  min?: string;
  max?: string;
  label?: string;
  autoComplete?: string;
  cornerHint?: string;
  helpText?: string;
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  placeholder?: string;
}

function Input({
  domId,
  cornerHint,
  helpText,
  Icon,
  autoComplete,
  inputType,
  min,
  max,
  placeholder,
  label,
  register,
  errors,
}: Props) {
  const error: DeepMap<FieldValues, FieldErrors> | null = errors[register.name] || null;

  return (
    <div>
      <div className="flex justify-between">
        <label htmlFor={domId} className="block text-sm font-medium font-roboto text-gray-700">
          {label}
        </label>
        {cornerHint && <span className="text-sm text-gray-500">{cornerHint}</span>}
      </div>
      <div
        className={classnames('mt-1', {
          'relative rounded-md shadow-sm': Icon !== undefined,
        })}
      >
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <input
          type={inputType}
          id={domId}
          className={classnames(
            {
              'shadow-sm focus:ring-primary focus:border-primary block w-full placeholder-gray-400 sm:text-sm border-gray-300 rounded-md':
                error === null,
            },
            {
              'block w-full border-red-300 text-red-900 placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md':
                error !== null,
            },
            { 'pl-10': Icon !== undefined },
          )}
          defaultValue={inputType === 'number' ? 0 : ''}
          placeholder={placeholder}
          aria-describedby={register.name}
          {...(autoComplete && { autoComplete })}
          {...(min && { min })}
          {...(max && { max })}
          {...register}
        />
      </div>
      {helpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${domId}-description`}>
          {helpText}
        </p>
      )}
      {error && error.message !== REQUIRED_FIELD && (
        <p className="mt-2 text-sm text-red-600" id={`${domId}-error`}>
          {error.message}
        </p>
      )}
    </div>
  );
}

Input.defaultProps = {
  cornerHint: '',
  helpText: '',
  placeholder: '',
  label: '',
  inputType: 'text',
  min: undefined,
  max: undefined,
  autoComplete: undefined,
  Icon: undefined,
};

export default Input;
