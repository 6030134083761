import { XIcon } from '@heroicons/react/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

import { Size, VariantColor } from '../../../types';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import Input from '../../atoms/Input';
import createInvoiceSchema from '../../../schemas/createInvoice';
import { CreateInvoiceFormValues } from '../../../pages/patient/types';
import { paymentMethods } from '../../../utils/constants';

interface Props {
  setCreateInvoiceMode: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: SubmitHandler<CreateInvoiceFormValues>;
  loading: boolean;
  patientFirstname: string;
  patientLastname: string;
}

function CreateInvoiceForm({
  setCreateInvoiceMode,
  onSubmit,
  loading,
  patientFirstname,
  patientLastname,
}: Props) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(paymentMethods[0]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<CreateInvoiceFormValues>({
    resolver: yupResolver(createInvoiceSchema),
    defaultValues: {
      price: 60,
      firstname: patientFirstname,
      lastname: patientLastname,
    },
  });

  useEffect(() => {
    setSelectedPaymentMethod(paymentMethods[0]);
    setValue('paymentMethod', paymentMethods[0]);
  }, [setValue]);

  return (
    <div className="px-6 py-4">
      <Button
        classNames="mx-2"
        color={VariantColor.SECONDARY}
        Icon={XIcon}
        size={Size.S}
        onClick={() => setCreateInvoiceMode(false)}
      />
      <span className="text-lg font-bold my-4">Annuler la création de la facture</span>
      <form
        className="grid grid-cols-4 gap-6 mt-4"
        onSubmit={handleSubmit(onSubmit)}
        action="#"
        method="POST"
      >
        <div className="col-span-2 sm:col-span-2 lg:col-span-2">
          <Select
            label="Moyen de Paiement"
            control={control}
            name="paymentMethod"
            selected={selectedPaymentMethod}
            setSelected={setSelectedPaymentMethod}
            values={paymentMethods}
          />
        </div>
        <div className="col-span-2 sm:col-span-2 lg:col-span-2">
          <Input
            domId="price"
            label="Prix"
            errors={errors}
            register={register('price')}
            inputType="number"
          />
        </div>
        <div className="col-span-2 sm:col-span-2 lg:col-span-2">
          <Input
            domId="lastname"
            label="Nom de la facture"
            errors={errors}
            register={register('lastname')}
            placeholder="Nom"
          />
        </div>
        <div className="col-span-2 sm:col-span-2 lg:col-span-2">
          <Input
            domId="firstname"
            label="Prénom de la facture"
            errors={errors}
            register={register('firstname')}
            placeholder="Prénom"
          />
        </div>
        <div className="col-span-4 flex justify-center my-4">
          <Button Icon={PlusIcon} submit disabled={loading}>
            Créer la facture
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CreateInvoiceForm;
