/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query Consultation($consultationUuid: Uuid!) {\n    consultation(consultationUuid: $consultationUuid) {\n      uuid\n      patientUuid\n      practitionerUuid\n      date\n      createdAt\n      medicalOfficeUuid\n      invoiceUuid\n      cause\n      remark\n      treatment\n      advice\n      evolution\n      practitioner {\n        lastnameFirstname\n        uuid\n      }\n      medicalOffice {\n        name\n        uuid\n      }\n      associatedInvoice {\n        date\n        price\n        paymentMethod\n        firstname\n        lastname\n        uuid\n      }\n    }\n  }\n": types.ConsultationDocument,
    "\n  query consultationsCountWeekly {\n    consultationsCountWeekly {\n      count\n      practitionerUuid\n      firstname\n      lastname\n    }\n  }\n": types.ConsultationsCountWeeklyDocument,
    "\n  mutation CreateConsultation($consultationInput: ConsultationInput!) {\n    createConsultation(consultationInput: $consultationInput) {\n      uuid\n    }\n  }\n": types.CreateConsultationDocument,
    "\n  mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput!) {\n    createInvoice(createInvoiceInput: $createInvoiceInput) {\n      uuid\n    }\n  }\n": types.CreateInvoiceDocument,
    "\n  mutation CreatePatient($patientInput: PatientInput!) {\n    createPatient(patientInput: $patientInput) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        medical\n        family\n        traumatic\n      }\n      checkUp {\n        thoracic\n        skull\n        gynecology\n        digestive\n      }\n      lastConsultationDate\n    }\n  }\n": types.CreatePatientDocument,
    "\n  mutation DeleteInvoice($invoiceUuid: Uuid!) {\n    deleteInvoice(invoiceUuid: $invoiceUuid)\n  }\n": types.DeleteInvoiceDocument,
    "\n  query downloadInvoice($invoiceUuid: Uuid!) {\n    downloadInvoice(invoiceUuid: $invoiceUuid)\n  }\n": types.DownloadInvoiceDocument,
    "\n  query getMedicalOfficePractitioners {\n    practitioners {\n      uuid\n      lastnameFirstname\n      linkedUserUuid\n    }\n    medicalOffices {\n      uuid\n      name\n    }\n  }\n": types.GetMedicalOfficePractitionersDocument,
    "\n  mutation login($input: LoginInput!) {\n    login(loginInput: $input)\n  }\n": types.LoginDocument,
    "\n  query me {\n    me {\n      email\n      userRole\n      uuid\n    }\n  }\n": types.MeDocument,
    "\n  mutation ModifyConsultation(\n    $modifyConsultationInput: ModifyConsultationInput!\n    $consultationUuid: Uuid!\n  ) {\n    modifyConsultation(\n      modifyConsultationInput: $modifyConsultationInput\n      consultationUuid: $consultationUuid\n    ) {\n      uuid\n    }\n  }\n": types.ModifyConsultationDocument,
    "\n  mutation ModifyInvoice($modifyInvoiceInput: ModifyInvoiceInput!) {\n    modifyInvoice(modifyInvoiceInput: $modifyInvoiceInput) {\n      uuid\n    }\n  }\n": types.ModifyInvoiceDocument,
    "\n  mutation ModifyPatient($modifyPatientInput: ModifyPatientInput!, $patientUuid: Uuid!) {\n    modifyPatient(modifyPatientInput: $modifyPatientInput, patientUuid: $patientUuid) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        family\n        medical\n        traumatic\n      }\n      checkUp {\n        digestive\n        gynecology\n        skull\n        thoracic\n      }\n      lastConsultationDate\n    }\n  }\n": types.ModifyPatientDocument,
    "\n  query Consultations(\n    $skip: Float\n    $take: Float\n    $sort: ConsultationsQueryOrderByInput\n    $filters: ConsultationsQueryFilters\n  ) {\n    consultations(skip: $skip, take: $take, sort: $sort, filters: $filters) {\n      hasMore\n      totalItemsCount\n      items {\n        uuid\n        date\n        practitioner {\n          lastnameFirstname\n        }\n      }\n    }\n  }\n": types.ConsultationsDocument,
    "\n  query Patient($patientUuid: Uuid!) {\n    patient(patientUuid: $patientUuid) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        traumatic\n        medical\n        family\n      }\n      checkUp {\n        skull\n        thoracic\n        digestive\n        gynecology\n      }\n      lastConsultationDate\n      consultationsCount\n      invoices {\n        uuid\n        date\n        price\n        firstname\n        lastname\n        paymentMethod\n        medicalOffice {\n          name\n        }\n        associatedConsultation {\n          uuid\n          practitioner {\n            lastnameFirstname\n          }\n        }\n      }\n    }\n  }\n": types.PatientDocument,
    "\n  query PatientNumberConsultationLastCurrentYearStat(\n    $patientUuid: Uuid!\n    $currentYear: Float!\n    $lastYear: Float!\n  ) {\n    patient(patientUuid: $patientUuid) {\n      uuid\n      numberConsultationsPerMonthCurrentYear: numberConsultationsPerMonthOverYear(\n        year: $currentYear\n      ) {\n        consultationsCount\n        month\n        year\n        patientUuid\n      }\n      numberConsultationsPerMonthLastYear: numberConsultationsPerMonthOverYear(year: $lastYear) {\n        consultationsCount\n        month\n        year\n        patientUuid\n      }\n    }\n  }\n": types.PatientNumberConsultationLastCurrentYearStatDocument,
    "\n  query Patients(\n    $skip: Float\n    $take: Float\n    $sort: PatientsQueryOrderByInput\n    $filters: PatientsQueryFilters\n  ) {\n    patients(skip: $skip, take: $take, sort: $sort, filters: $filters) {\n      hasMore\n      totalItemsCount\n      items {\n        birthdate\n        lastConsultationDate\n        email\n        phoneNumber\n        firstname\n        lastname\n        uuid\n      }\n    }\n  }\n": types.PatientsDocument,
    "\n  mutation SendInvoiceByMail($invoiceUuid: Uuid!, $customMessage: String) {\n    sendInvoiceByMail(invoiceUuid: $invoiceUuid, customMessage: $customMessage)\n  }\n": types.SendInvoiceByMailDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Consultation($consultationUuid: Uuid!) {\n    consultation(consultationUuid: $consultationUuid) {\n      uuid\n      patientUuid\n      practitionerUuid\n      date\n      createdAt\n      medicalOfficeUuid\n      invoiceUuid\n      cause\n      remark\n      treatment\n      advice\n      evolution\n      practitioner {\n        lastnameFirstname\n        uuid\n      }\n      medicalOffice {\n        name\n        uuid\n      }\n      associatedInvoice {\n        date\n        price\n        paymentMethod\n        firstname\n        lastname\n        uuid\n      }\n    }\n  }\n"): (typeof documents)["\n  query Consultation($consultationUuid: Uuid!) {\n    consultation(consultationUuid: $consultationUuid) {\n      uuid\n      patientUuid\n      practitionerUuid\n      date\n      createdAt\n      medicalOfficeUuid\n      invoiceUuid\n      cause\n      remark\n      treatment\n      advice\n      evolution\n      practitioner {\n        lastnameFirstname\n        uuid\n      }\n      medicalOffice {\n        name\n        uuid\n      }\n      associatedInvoice {\n        date\n        price\n        paymentMethod\n        firstname\n        lastname\n        uuid\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query consultationsCountWeekly {\n    consultationsCountWeekly {\n      count\n      practitionerUuid\n      firstname\n      lastname\n    }\n  }\n"): (typeof documents)["\n  query consultationsCountWeekly {\n    consultationsCountWeekly {\n      count\n      practitionerUuid\n      firstname\n      lastname\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateConsultation($consultationInput: ConsultationInput!) {\n    createConsultation(consultationInput: $consultationInput) {\n      uuid\n    }\n  }\n"): (typeof documents)["\n  mutation CreateConsultation($consultationInput: ConsultationInput!) {\n    createConsultation(consultationInput: $consultationInput) {\n      uuid\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput!) {\n    createInvoice(createInvoiceInput: $createInvoiceInput) {\n      uuid\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput!) {\n    createInvoice(createInvoiceInput: $createInvoiceInput) {\n      uuid\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePatient($patientInput: PatientInput!) {\n    createPatient(patientInput: $patientInput) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        medical\n        family\n        traumatic\n      }\n      checkUp {\n        thoracic\n        skull\n        gynecology\n        digestive\n      }\n      lastConsultationDate\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePatient($patientInput: PatientInput!) {\n    createPatient(patientInput: $patientInput) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        medical\n        family\n        traumatic\n      }\n      checkUp {\n        thoracic\n        skull\n        gynecology\n        digestive\n      }\n      lastConsultationDate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteInvoice($invoiceUuid: Uuid!) {\n    deleteInvoice(invoiceUuid: $invoiceUuid)\n  }\n"): (typeof documents)["\n  mutation DeleteInvoice($invoiceUuid: Uuid!) {\n    deleteInvoice(invoiceUuid: $invoiceUuid)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query downloadInvoice($invoiceUuid: Uuid!) {\n    downloadInvoice(invoiceUuid: $invoiceUuid)\n  }\n"): (typeof documents)["\n  query downloadInvoice($invoiceUuid: Uuid!) {\n    downloadInvoice(invoiceUuid: $invoiceUuid)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMedicalOfficePractitioners {\n    practitioners {\n      uuid\n      lastnameFirstname\n      linkedUserUuid\n    }\n    medicalOffices {\n      uuid\n      name\n    }\n  }\n"): (typeof documents)["\n  query getMedicalOfficePractitioners {\n    practitioners {\n      uuid\n      lastnameFirstname\n      linkedUserUuid\n    }\n    medicalOffices {\n      uuid\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation login($input: LoginInput!) {\n    login(loginInput: $input)\n  }\n"): (typeof documents)["\n  mutation login($input: LoginInput!) {\n    login(loginInput: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query me {\n    me {\n      email\n      userRole\n      uuid\n    }\n  }\n"): (typeof documents)["\n  query me {\n    me {\n      email\n      userRole\n      uuid\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ModifyConsultation(\n    $modifyConsultationInput: ModifyConsultationInput!\n    $consultationUuid: Uuid!\n  ) {\n    modifyConsultation(\n      modifyConsultationInput: $modifyConsultationInput\n      consultationUuid: $consultationUuid\n    ) {\n      uuid\n    }\n  }\n"): (typeof documents)["\n  mutation ModifyConsultation(\n    $modifyConsultationInput: ModifyConsultationInput!\n    $consultationUuid: Uuid!\n  ) {\n    modifyConsultation(\n      modifyConsultationInput: $modifyConsultationInput\n      consultationUuid: $consultationUuid\n    ) {\n      uuid\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ModifyInvoice($modifyInvoiceInput: ModifyInvoiceInput!) {\n    modifyInvoice(modifyInvoiceInput: $modifyInvoiceInput) {\n      uuid\n    }\n  }\n"): (typeof documents)["\n  mutation ModifyInvoice($modifyInvoiceInput: ModifyInvoiceInput!) {\n    modifyInvoice(modifyInvoiceInput: $modifyInvoiceInput) {\n      uuid\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ModifyPatient($modifyPatientInput: ModifyPatientInput!, $patientUuid: Uuid!) {\n    modifyPatient(modifyPatientInput: $modifyPatientInput, patientUuid: $patientUuid) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        family\n        medical\n        traumatic\n      }\n      checkUp {\n        digestive\n        gynecology\n        skull\n        thoracic\n      }\n      lastConsultationDate\n    }\n  }\n"): (typeof documents)["\n  mutation ModifyPatient($modifyPatientInput: ModifyPatientInput!, $patientUuid: Uuid!) {\n    modifyPatient(modifyPatientInput: $modifyPatientInput, patientUuid: $patientUuid) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        family\n        medical\n        traumatic\n      }\n      checkUp {\n        digestive\n        gynecology\n        skull\n        thoracic\n      }\n      lastConsultationDate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Consultations(\n    $skip: Float\n    $take: Float\n    $sort: ConsultationsQueryOrderByInput\n    $filters: ConsultationsQueryFilters\n  ) {\n    consultations(skip: $skip, take: $take, sort: $sort, filters: $filters) {\n      hasMore\n      totalItemsCount\n      items {\n        uuid\n        date\n        practitioner {\n          lastnameFirstname\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Consultations(\n    $skip: Float\n    $take: Float\n    $sort: ConsultationsQueryOrderByInput\n    $filters: ConsultationsQueryFilters\n  ) {\n    consultations(skip: $skip, take: $take, sort: $sort, filters: $filters) {\n      hasMore\n      totalItemsCount\n      items {\n        uuid\n        date\n        practitioner {\n          lastnameFirstname\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Patient($patientUuid: Uuid!) {\n    patient(patientUuid: $patientUuid) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        traumatic\n        medical\n        family\n      }\n      checkUp {\n        skull\n        thoracic\n        digestive\n        gynecology\n      }\n      lastConsultationDate\n      consultationsCount\n      invoices {\n        uuid\n        date\n        price\n        firstname\n        lastname\n        paymentMethod\n        medicalOffice {\n          name\n        }\n        associatedConsultation {\n          uuid\n          practitioner {\n            lastnameFirstname\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Patient($patientUuid: Uuid!) {\n    patient(patientUuid: $patientUuid) {\n      uuid\n      createdAt\n      firstname\n      lastname\n      firstnameWithoutDiacritics\n      lastnameWithoutDiacritics\n      gender\n      email\n      phoneNumber\n      birthdate\n      job\n      hobbies\n      address\n      zipCode\n      city\n      remark\n      caseHistory {\n        traumatic\n        medical\n        family\n      }\n      checkUp {\n        skull\n        thoracic\n        digestive\n        gynecology\n      }\n      lastConsultationDate\n      consultationsCount\n      invoices {\n        uuid\n        date\n        price\n        firstname\n        lastname\n        paymentMethod\n        medicalOffice {\n          name\n        }\n        associatedConsultation {\n          uuid\n          practitioner {\n            lastnameFirstname\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PatientNumberConsultationLastCurrentYearStat(\n    $patientUuid: Uuid!\n    $currentYear: Float!\n    $lastYear: Float!\n  ) {\n    patient(patientUuid: $patientUuid) {\n      uuid\n      numberConsultationsPerMonthCurrentYear: numberConsultationsPerMonthOverYear(\n        year: $currentYear\n      ) {\n        consultationsCount\n        month\n        year\n        patientUuid\n      }\n      numberConsultationsPerMonthLastYear: numberConsultationsPerMonthOverYear(year: $lastYear) {\n        consultationsCount\n        month\n        year\n        patientUuid\n      }\n    }\n  }\n"): (typeof documents)["\n  query PatientNumberConsultationLastCurrentYearStat(\n    $patientUuid: Uuid!\n    $currentYear: Float!\n    $lastYear: Float!\n  ) {\n    patient(patientUuid: $patientUuid) {\n      uuid\n      numberConsultationsPerMonthCurrentYear: numberConsultationsPerMonthOverYear(\n        year: $currentYear\n      ) {\n        consultationsCount\n        month\n        year\n        patientUuid\n      }\n      numberConsultationsPerMonthLastYear: numberConsultationsPerMonthOverYear(year: $lastYear) {\n        consultationsCount\n        month\n        year\n        patientUuid\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Patients(\n    $skip: Float\n    $take: Float\n    $sort: PatientsQueryOrderByInput\n    $filters: PatientsQueryFilters\n  ) {\n    patients(skip: $skip, take: $take, sort: $sort, filters: $filters) {\n      hasMore\n      totalItemsCount\n      items {\n        birthdate\n        lastConsultationDate\n        email\n        phoneNumber\n        firstname\n        lastname\n        uuid\n      }\n    }\n  }\n"): (typeof documents)["\n  query Patients(\n    $skip: Float\n    $take: Float\n    $sort: PatientsQueryOrderByInput\n    $filters: PatientsQueryFilters\n  ) {\n    patients(skip: $skip, take: $take, sort: $sort, filters: $filters) {\n      hasMore\n      totalItemsCount\n      items {\n        birthdate\n        lastConsultationDate\n        email\n        phoneNumber\n        firstname\n        lastname\n        uuid\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendInvoiceByMail($invoiceUuid: Uuid!, $customMessage: String) {\n    sendInvoiceByMail(invoiceUuid: $invoiceUuid, customMessage: $customMessage)\n  }\n"): (typeof documents)["\n  mutation SendInvoiceByMail($invoiceUuid: Uuid!, $customMessage: String) {\n    sendInvoiceByMail(invoiceUuid: $invoiceUuid, customMessage: $customMessage)\n  }\n"];

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function graphql(source: string): unknown;

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;