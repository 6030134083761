import classnames from 'classnames';
import React from 'react';

interface Props {
  tabs: string[];
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

function Tabs({ tabs, currentTab, setCurrentTab }: Props) {
  if (tabs.length === 0) {
    return <div />;
  }

  return (
    <div>
      <div className="sm:hidden">
        <select className="block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary">
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
          {tabs.map((tab, tabIdx) => (
            <span
              key={tab}
              onClick={() => setCurrentTab(tab)}
              className={classnames(
                tab === currentTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden cursor-pointer bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
              )}
            >
              <span>{tab}</span>
              <span
                className={classnames(
                  tab === currentTab ? 'bg-primary' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5',
                )}
              />
            </span>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default Tabs;
