import { graphql } from '../types/codegen';

export default graphql(`
  mutation ModifyPatient($modifyPatientInput: ModifyPatientInput!, $patientUuid: Uuid!) {
    modifyPatient(modifyPatientInput: $modifyPatientInput, patientUuid: $patientUuid) {
      uuid
      createdAt
      firstname
      lastname
      firstnameWithoutDiacritics
      lastnameWithoutDiacritics
      gender
      email
      phoneNumber
      birthdate
      job
      hobbies
      address
      zipCode
      city
      remark
      caseHistory {
        family
        medical
        traumatic
      }
      checkUp {
        digestive
        gynecology
        skull
        thoracic
      }
      lastConsultationDate
    }
  }
`);
