import { graphql } from '../types/codegen';

export default graphql(`
  query PatientNumberConsultationLastCurrentYearStat(
    $patientUuid: Uuid!
    $currentYear: Float!
    $lastYear: Float!
  ) {
    patient(patientUuid: $patientUuid) {
      uuid
      numberConsultationsPerMonthCurrentYear: numberConsultationsPerMonthOverYear(
        year: $currentYear
      ) {
        consultationsCount
        month
        year
        patientUuid
      }
      numberConsultationsPerMonthLastYear: numberConsultationsPerMonthOverYear(year: $lastYear) {
        consultationsCount
        month
        year
        patientUuid
      }
    }
  }
`);
